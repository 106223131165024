<template>
  <Modal id="NewJoinReport" width="70%" :visible="visible" @cancel="visible = !visible" @ok="submit"
    :confirm-loading="confirmLoading" :title="$t('report.name.down_yearly_payroll_report')">
    <Row align="middle" justify="center">
      <Col span="18" class="content">
      <Row>
        <Col span="24">
        <Form ref="newJoinReportForm" :model="form" layout="vertical" :rules="validateRules">
          <Row justify="space-around">
            <Col span="10">
            <FormItem :label="$t('attendance.name.dateRange')">
              <RangePicker v-model:value="rangeValue" format="YYYY-MM" :mode="mode" :allowClear="false"
                v-model="rangeValue" :open="isRangeOpen" @openChange="openRangeChange"
                @panelChange="handlePanelChange2" />
            </FormItem>
            </Col>
          </Row>
        </Form>
        </Col>
      </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  RangePicker,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { ReportDownYearlyPayrollReport } from "@/api/ReportDownYearlyPayrollReport";
import moment from "moment";

export default {
  name: "NewJoinReport",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    RangePicker,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      actionType: "",
      mode: ["month", "month"],
      rangeValue: [moment().subtract(1, 'year').month(3), moment().month(2)],
      isRangeOpen: false,
      form: {
        startMonth: moment().subtract(1, 'year').month(3).format('YYYY-MM'),
        endMonth: moment().month(2).format('YYYY-MM'),
      },
      fileList: {},
      selectList: {

      },
      validateRules: {
        idEmp: [
          {
            required: true,
            message: this.$t("leave.validate.required.id_emp"),
            trigger: "change",
            type: "number",
          },
        ],
        dateRange: [
          {
            required: true,
            message: this.$t("downloadLeaveReport.validate.required.date_range"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    async show() {
      this._resetData();
      this.visible = true;
    },
    submit() {
      this.$refs["newJoinReportForm"]
        .validate()
        .then(() => {
          debugger
          const api = new ReportDownYearlyPayrollReport();
          api.download(this.form).then((response) => {
            if (!response) {
              return
            }
            if(response.data.type == 'application/json'){
              message.error(this.$t("public.name.no_data"))
              return
            }
            let url = window.URL.createObjectURL(new Blob([response.data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', 'YearlyPayrollReport.pdf')
            document.body.appendChild(link)
            link.click()
          });
        })
        .catch(() => { });
    },

    openRangeChange(status) {
      if (status) {
        this.isRangeOpen = true;
      } else {
        this.isRangeOpen = false;
      }
    },
    handlePanelChange2(value) {

      this.rangeValue = value;
      this.form.startMonth = value[0].format("YYYY-MM")
      this.form.endMonth = value[1].format("YYYY-MM")
      
      this.isRangeOpen = false;

    },

    // reset data
    _resetData() {
      this.rangePickerDate = [null, null],
        this.form = {
          startMonth: moment().subtract(1, 'year').month(3).format('YYYY-MM'),
          endMonth: moment().month(2).format('YYYY-MM'),
        };
    },
  },
};
</script>

<style lang="less">
</style>