<template>
  <Modal
    id="RoleEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="roleForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem :label="$t('role.field.id_dep')" name="idDep">
                    <!--关联表的下拉选择-->
                    <Select
                      v-model:value="form.idDep"
                      :placeholder="$t('role.validate.required.id_dep')"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                      <SelectOption
                        :key="sk"
                        v-for="(item, sk) in selectList['departmentList']"
                        :value="item['idDep']"
                        >{{ item["name"] }}</SelectOption
                      >
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('role.field.name')" name="name">
                    <Input
                      :placeholder="$t('role.validate.required.name')"
                      v-model:value="form.name"
                    >
                    </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                    :label="$t('role.field.authorityList')"
                    name="authorityList"
                    ref="authorityList"
                  >
                    <Tree
                      checkable
                      :tree-data="selectList.treeData"
                      v-model:checkedKeys="checkedKeys"
                      @check="checkTree"
                    />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('role.field.approver_id')" name="approverId">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.approverId"
                              :placeholder="$t('role.validate.required.approver_id')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nickName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('role.field.approver2_id')" name="approver2Id">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.approver2Id"
                              :placeholder="$t('role.validate.required.approver2_id')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      <SelectOption :value="null">{{ $t('public.name.select_none') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nickName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem :label="$t('role.field.remark')" name="remark">
                    <Textarea
                      v-model:value="form.remark"
                      :placeholder="$t('role.validate.required.remark')"
                      :auto-size="{ minRows: 5, maxRows: 10 }"
                      showCount
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Tree,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { RoleAdd } from "@/api/RoleAdd";
import { RoleEdit } from "@/api/RoleEdit";
import { DepartmentListAll } from "@/api/DepartmentListAll";
import { AuthorityListAll } from "@/api/AuthorityListAll";
import { EmployeeListAll } from "@/api/EmployeeListAll"

export default {
  name: "RoleEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Tree,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idRol: "",
        idDep: "",
        name: "",
        remark: "",
        authorityList: [],
        approverId : "",
        approver2Id : "",
      },
      //全选和半选样式
      checkedKeys: {
        checked: [],
        halfChecked: [],
      },
      fileList: {},
      selectList: {
        employeeList:[],
        departmentList: [],
        treeData: [],
      },
      validateRules: {
        idRol: [
          {
            required: true,
            message: this.$t("role.validate.required.id_rol"),
            trigger: "change",
          },
        ],
        idDep: [
          {
            required: true,
            message: this.$t("role.validate.required.id_dep"),
            trigger: "change",
            type: "number",
          },
        ],
        name: [
          {
            required: true,
            message: this.$t("role.validate.required.name"),
            trigger: "change",
          },
        ],
        remark: [
          {
            required: false,
            message: this.$t("role.validate.required.remark"),
            trigger: "change",
          },
        ],
        authorityList: [
          {
            required: true,
            min: 1,
            type: "array",
            message: this.$t("role.validate.required.authorityList"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  mounted() {
    this.getAuthorityTree();
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getDepartmentList();
      this.getEmployeeList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.getEmployeeList();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.form.authorityList = this.form.authorityList.map((item) => {
        return item.idAut
      })
    
      this.form.authorityList.forEach((idAut) => {
        var isHalf = this.isHalfChecked(idAut,this.form.authorityList);
        if(isHalf){
          this.checkedKeys.halfChecked.push(idAut)
        }else{
          this.checkedKeys.checked.push(idAut)
        }
      })
      this.getDepartmentList();
      this.visible = true;
    },
    submit() {
      this.$refs["roleForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              api = new RoleAdd();
              break;
            case "edit":
              api = new RoleEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {});
    },
    getEmployeeList() {
      const api = new EmployeeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.employeeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getDepartmentList() {
      const api = new DepartmentListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.departmentList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idRol: "",
        idDep: "",
        name: "",
        remark: "",
        authorityList: [],
	        approverId : "",
        approver2Id : "",
      };
      this.checkedKeys = {
        checked: [],
        halfChecked: [],
      };
    },
    tree_init(data) {
      var parentArr = data.filter((item) => {
        return !item.aIdAut;
      });
      var resultArr = [];
      parentArr.forEach((item) => {
        var children = this.getChildren(item, data);
        if (children.length > 0) {
          resultArr.push({
            key: item.idAut,
            value: item.idAut,
            title: item.name,
            children: children,
          });
        } else {
          resultArr.push({
            key: item.idAut,
            value: item.idAut,
            title: item.name,
          });
        }
      });
      return resultArr;
    },
    getChildren(data, list) {
      let arr = [];
      list.forEach((item) => {
        if (data.idAut === item.aIdAut) {
          let subArr = this.getChildren(item, list);
          if (subArr.length === 0) {
            arr.push({
              key: item.idAut,
              value: item.idAut,
              title: item.name,
            });
          } else {
            arr.push({
              key: item.idAut,
              value: item.idAut,
              title: item.name,
              children: subArr,
            });
          }
        }
      });
      return arr;
    },
    getAuthorityTree() {
      const api = new AuthorityListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          // this.selectList.authorityList = response.data.data;
          let treeData = [];
          treeData = this.tree_init(response.data.data);
          this.selectList.treeData = treeData;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    buildList(tree) {
      var list = []
      if(Array.isArray(tree)){
        tree.forEach((item) => {
          list.push(item)
          if (item.children && item.children.length !== 0) {
            var subList = this.buildList(item.children)
            list = list.concat(subList)
          }
        })
      }else{
        list.push(tree)
      }
      return list
    },
    //遍历树结构，获取某节点所有子节点的id
    getTreeChildrenId(children){
      var list = []
      children.forEach((item) => {
        list.push(item.key)
        if(item.children != null){
          list.push(this.getTreeChildrenId(item.children))
        }
      })
      return list
    },
    isContainArr(parent, child) {
      return child.every(item => {
        return parent.some(v => {
          return item == v
        })
      })
    },
    isHalfChecked(idAut,dataArr){
      var list = this.buildList(this.selectList.treeData)
      var treeNode = list.find((item) => {
        return item.key === idAut
      })
      if(treeNode.children){
        var arr = this.getTreeChildrenId(treeNode.children)
        return !this.isContainArr(dataArr,arr)
      }else{
        return false
      }
      
    },
    checkTree(checkedKeys,e){
      let concatTreeData =  checkedKeys.concat(e.halfCheckedKeys)
      this.form.authorityList = concatTreeData
    }
  },
};
</script>

<style lang="less">
</style>