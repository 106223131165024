<template>
  <Modal id="CompanyDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('annualLeavePlan.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
        <DescriptionsItem :label="$t('annualLeavePlan.field.id_ann_lea_pla')">{{data.idAnnLeaPla}}</DescriptionsItem>
        <DescriptionsItem :label="$t('annualLeavePlan.field.plan_name')">{{data.planName}}</DescriptionsItem>
        <DescriptionsItem :label="$t('annualLeavePlan.field.annual_leave_days')">{{data.annualLeaveDays}}</DescriptionsItem>
        <!-- <DescriptionsItem :label="$t('annualLeavePlan.field.maternity_leave_days')">{{data.maternityLeaveDays}}</DescriptionsItem>
        <DescriptionsItem :label="$t('annualLeavePlan.field.paternity_leave_days')">{{data.paternityLeaveDays}}</DescriptionsItem> -->
      <DescriptionsItem :label="$t('annualLeavePlan.field.annual_leave_days_year_imcrement')">{{data.annualLeaveDaysYearImcrement}}</DescriptionsItem>
      <DescriptionsItem :label="$t('annualLeavePlan.field.max_annual_leave_days')">{{data.maxAnnualLeaveDays}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "AnnualLeavePlanDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>