import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store"
import Login from '@/views/Login'
import Home from '@/views/Home'
import AnnualLeavePlanManage from '@/views/Home/AnnualLeavePlanManage'
import AttendanceManage from '@/views/Home/AttendanceManage'
import AttendanceLogsManage from '@/views/Home/AttendanceLogsManage'
import AuthorityManage from '@/views/Home/AuthorityManage'
import DepartmentManage from '@/views/Home/DepartmentManage'
import EmployeeManage from '@/views/Home/EmployeeManage'
import EmployeeEntryRecordManage from '@/views/Home/EmployeeEntryRecordManage'
import HolidayManage from '@/views/Home/HolidayManage'
import LeaveManage from '@/views/Home/LeaveManage'
import LeaveTypeManage from '@/views/Home/LeaveTypeManage'
import SelfLeaveManage from '@/views/Home/SelfLeaveManage'
import OvertimeApplicationManage from '@/views/Home/OvertimeApplicationManage'
import OvertimeApplicationApprove from '@/views/OvertimeApplicationApprove'
import SelfOvertimeApplicationManage from '@/views/Home/SelfOvertimeApplicationManage'
import RemainingVacationManage from '@/views/Home/RemainingVacationManage'
import ReportManage from '@/views/Home/ReportManage'
import RoleManage from '@/views/Home/RoleManage'
import SalaryInformationManage from '@/views/Home/SalaryInformationManage'
import NewLeaveApprove from '@/views/NewLeaveApprove'
import MpfSettingManage from '@/views/Home/MpfSettingManage'
import SettingsManage from '@/views/Home/SettingsManage'
import WorkingSegmentManage from '@/views/Home/WorkingSegmentManage'


const routes = [
  {
    path: "/",
    name: "Login",
    component: Login
  },
  {
    path: "/LeaveApprove/:idLea",
    name: "LeaveApprove",
    component: NewLeaveApprove,
    beforeEnter: (to, from, next) => {
      // token存在 进入该路由，不存在跳转到登陆页面
      console.log('进了拦截器！！')
      if (store.getters.getToken) {
        console.log('token')
        console.log(store.getters.getToken)
        next()
      } else {
        console.log('to.fullPath:' + to.fullPath)
        next({
          name:"Login",
          query: {redirect: to.fullPath}//将目的路由地址存入login的query中
        })
      }
    },
  },
  {
    path: "/OvertimeApplicationApprove/:idOveApp",
    name: "OvertimeApplicationApprove",
    component: OvertimeApplicationApprove,
    beforeEnter: (to, from, next) => {
      // token存在 进入该路由，不存在跳转到登陆页面
      if (store.getters.getToken) {
        //console.log(store.getters.getToken)
        next()
      } else {
        next({
          name:"Login",
          query: {redirect: to.fullPath}//将目的路由地址存入login的query中
        })
      }
    },
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      // token存在 进入该路由，不存在跳转到登陆页面
      if (store.getters.getToken) {
        //console.log(store.getters.getToken)
        next()
      } else {
        next('/Login')
      }
    },
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home/Dashboard.vue')
      },
      {
        path: "/AnnualLeavePlanManage",
        name: "AnnualLeavePlanManage",
        component: AnnualLeavePlanManage
      },
      {
        path: "/AttendanceManage",
        name: "AttendanceManage",
        component: AttendanceManage
      },
      {
        path: "/AttendanceLogsManage",
        name: "AttendanceLogsManage",
        component: AttendanceLogsManage
      },
      {
        path: "/AuthorityManage",
        name: "AuthorityManage",
        component: AuthorityManage
      },
      {
        path: "/DepartmentManage",
        name: "DepartmentManage",
        component: DepartmentManage
      },
      {
        path: "/EmployeeManage",
        name: "EmployeeManage",
        component: EmployeeManage
      },
      {
        path: "/EmployeeEntryRecordManage",
        name: "EmployeeEntryRecordManage",
        component: EmployeeEntryRecordManage
      },
      {
        path: "/HolidayManage",
        name: "HolidayManage",
        component: HolidayManage
      },
      {
        path: "/LeaveManage",
        name: "LeaveManage",
        component: LeaveManage
      },
      {
        path: "/LeaveTypeManage",
        name: "LeaveTypeManage",
        component: LeaveTypeManage
      },
      {
        path: "/SelfLeaveManage",
        name: "SelfLeaveManage",
        component: SelfLeaveManage
      },
      {
        path: "/MpfSettingManage",
        name: "MpfSettingManage",
        component: MpfSettingManage
      },
      {
        path: "/OvertimeApplicationManage",
        name: "OvertimeApplicationManage",
        component: OvertimeApplicationManage
      },
      {
        path: "/SelfOvertimeApplicationManage",
        name: "SelfOvertimeApplicationManage",
        component: SelfOvertimeApplicationManage
      },
      {
        path: "/RemainingVacationManage",
        name: "RemainingVacationManage",
        component: RemainingVacationManage
      },
      {
        path: "/ReportManage",
        name: "ReportManage",
        component: ReportManage
      },
      {
        path: "/RoleManage",
        name: "RoleManage",
        component: RoleManage
      },
      {
        path: "/SalaryInformationManage",
        name: "SalaryInformationManage",
        component: SalaryInformationManage
      },
      {
        path: "/SettingsManage",
        name: "SettingsManage",
        component: SettingsManage
      },
      {
        path: "/WorkingSegmentManage",
        name: "WorkingSegmentManage",
        component: WorkingSegmentManage
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes
})

export default router