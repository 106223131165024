<template>
  <Row id="Role">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.RoleManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('attendance.name.department')" name="department">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="searchForm.idDep"
                      >
                      <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['departmentList']" :value="item['idDep']">{{ item['name'] }}</SelectOption>
                      </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idRol" :loading="loading" :pagination="tablePagination" @change="chang_page">
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('RoleManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
            <template #action="{text}">
              <div>
                <!-- <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('RoleManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('RoleManage.Delete')" type="primary" danger size="small" @click="del(text.idRol)">{{$t("public.name.del")}}</Button>
                </div> -->
                <Dropdown :trigger="['click']">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="detail(text)">{{$t('public.name.detail')}}</MenuItem>
                      <MenuItem v-if="checkPermission('RoleManage.Edit')" @click="edit(text)">{{$t('public.name.edit')}}</MenuItem>
                      <MenuItem v-if="checkPermission('RoleManage.Delete')" danger @click="del(text.idRol)">{{$t('public.name.del')}}</MenuItem>
                    </Menu>
                  </template>
                  <Button size="small">
                    {{$t("public.name.more")}}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <RoleDetail ref="refRoleDetail"></RoleDetail>
    <RoleEdit ref="refRoleEdit" @updateList="get_list"></RoleEdit>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { RoleList } from "@/api/RoleList";
import { RoleDelete } from "@/api/RoleDelete"
import { DepartmentListAll } from "@/api/DepartmentListAll";
import RoleDetail from "@/components/Home/RoleDetail";
import RoleEdit from "@/components/Home/RoleEdit";

export default {
  name: "RoleManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    RoleDetail,
    RoleEdit,
  },
  data() {
    return {
      searchForm: {
        idDep:null
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
          { title: this.$t("role.table.id_rol"), fixed: "left", dataIndex: "idRol", key: "idRol" },
          { title: this.$t("role.table.id_dep"), dataIndex: "departmentName", key: "departmentName" },
          { title: this.$t("role.table.name"), dataIndex: "name", key: "name" },
          { title: this.$t("role.table.remark"), dataIndex: "remark", key: "remark" },
          { title: this.$t("role.table.approver_id"), dataIndex: "approver", key: "approver" },
          { title: this.$t("role.table.approver2_id"), dataIndex: "approver2", key: "approver2" },
        { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList:{
        departmentList: [],
      }
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new RoleList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      if (this.searchForm.idDep) getData.idDep = this.searchForm.idDep
      // if (this.searchForm.status != 0) getData.status = this.searchForm.status
      // if (this.searchForm.order != "") getData.order = this.searchForm.order
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
            //如果當前處於不是第一頁，但是又沒有數據的情況，則把頁面設置為1，並重新請求
            if(this.list.length == 0 && this.tablePagination.current != 1){
              this.tablePagination.current = 1;
              this.get_list();
            }
          } else {
            this.list = [];
          }
        });
    },
    add() {
      this.$refs.refRoleEdit.addShow();
    },
    detail(obj) {
      this.$refs.refRoleDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refRoleEdit.editShow(obj);
    },
    del(idRol) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new RoleDelete();
          api.post({ idRol: idRol }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    getDepartmentList() {
      const api = new DepartmentListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.departmentList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.searchForm = {
        idDep:null
      };
      this.get_list()
    }
  },
  mounted() {
    this.get_list();
    this.getDepartmentList();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/role.less");
</style>