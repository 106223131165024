<template>
  <Modal
    id="WorkingSegmentEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="workingSegmentForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                  <Col span="10">
                    <FormItem :label="$t('workingSegment.field.seg_name')" name="segName">
                                            <Input :placeholder="$t('workingSegment.validate.required.seg_name')"
                                                   v-model:value="form.segName">
                                            </Input>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('workingSegment.field.start_time')" name="startTime">
                                            <!-- <Input :placeholder="$t('workingSegment.validate.required.start_time')"
                                                   v-model:value="form.startTime">
                                            </Input> -->
                      <TimePicker v-model:value="startTimeTemp" format="HH:mm" :minuteStep="30" @change="changeStartTime"/>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('workingSegment.field.end_time')" name="endTime">
                                            <!-- <Input :placeholder="$t('workingSegment.validate.required.end_time')"
                                                   v-model:value="form.endTime">
                                            </Input> -->
                      <TimePicker v-model:value="endTimeTemp" format="HH:mm" :minuteStep="30" @change="changeEndTime"/>
                  </FormItem>
                  </Col>
                  <Col span="10">
                    <FormItem :label="$t('workingSegment.field.druation')" name="druation">
                                            <InputNumber disabled :placeholder="$t('workingSegment.validate.required.druation')"
                                                   v-model:value="form.druation">
                                            </InputNumber>
                  </FormItem>
                  </Col>
                <!--判断是否有子表，如果有则直接加入子表管理逻辑-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  TimePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  Space,
  Card,
  Table,
  Popconfirm,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { WorkingSegmentAdd } from "@/api/WorkingSegmentAdd";
import { WorkingSegmentEdit } from "@/api/WorkingSegmentEdit";
import moment from "moment";

export default {
  name: "WorkingSegmentEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    Space,
    Card,
    Table,
    Popconfirm,
    TimePicker,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      startTimeTemp:null,
      endTimeTemp:null,
      form: {
        idWorSeg : "",
        segName : "",
        startTime : "",
        endTime : "",
        druation : "",
      },
      fileList: {
      },
      selectList: {
      },
      validateRules: {
        idWorSeg: [
          {
            required: true,
            message: this.$t("workingSegment.validate.required.id_wor_seg"),
            trigger: "change",
            type: "number",
          },
        ],
        segName: [
          {
            required: true,
            message: this.$t("workingSegment.validate.required.seg_name"),
            trigger: "change",
          },
        ],
        startTime: [
          {
            required: true,
            message: this.$t("workingSegment.validate.required.start_time"),
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: this.$t("workingSegment.validate.required.end_time"),
            trigger: "change",
          },
        ],
        druation: [
          {
            required: true,
            message: this.$t("workingSegment.validate.required.druation"),
            trigger: "change",
            type: "number"
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
      "visible": {
          deep: true,
          handler: function (newValue, oldValue) {
              if (!newValue) {
                  this.$emit("updateList");
              }
          }
      }
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
    submit() {
      this.$refs["workingSegmentForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new WorkingSegmentAdd();
              break;
            case "edit":
              console.log("edit");
              api = new WorkingSegmentEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList",response.data.data);
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    changeStartTime: function(val){
      console.log(val)
      this.form.startTime = val.format("HH:mm")
      this.caculateDuration()
    },
    changeEndTime: function(val){
      console.log(val)
      this.form.endTime = val.format("HH:mm")
      this.caculateDuration()
    },
    caculateDuration:function(){
      if(this.startTimeTemp && this.endTimeTemp){
        this.form.druation = this.endTimeTemp.diff(this.startTimeTemp,"hour",true)
      }
    },
    // reset data
    _resetData() {
      this.startTimeTemp = null;
      this.endTimeTemp = null;
      this.form = {
        idWorSeg : "",
        segName : "",
        startTime : "",
        endTime : "",
        druation : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>