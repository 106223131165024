<template>
  <Modal
    id="SalaryInformationEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="salaryInformationForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.id_emp')" name="idEmp">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idEmp"
                              :placeholder="$t('salaryInformation.validate.required.id_emp')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nickName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.base_salary')" name="baseSalary">
                      <InputNumber
                              v-model:value="form.baseSalary"
                              :placeholder="$t('salaryInformation.validate.required.base_salary')"
                              :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                              :min="0" :step="0.01"
                              style="width:120px"
                              @change="(val) => {
                                if(!val){
                                  this.form.baseSalary = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.allowance')" name="allowance">
                      <InputNumber
                              v-model:value="form.allowance"
                              :placeholder="$t('salaryInformation.validate.required.allowance')"
                              :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                              :min="0" :step="0.01"
                              style="width:120px"
                              @change="(val) => {
                                if(!val){
                                  this.form.allowance = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.car_subsidy')" name="carSubsidy">
                      <InputNumber
                              v-model:value="form.carSubsidy"
                              :placeholder="$t('salaryInformation.validate.required.car_subsidy')"
                              :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                              :min="0" :step="0.01"
                              style="width:120px"
                              @change="(val) => {
                                if(!val){
                                  this.form.carSubsidy = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col> -->
		<!-- <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.commission')" name="commission">
                      <InputNumber :placeholder="$t('salaryInformation.validate.required.commission')"
                             v-model:value="form.commission"
			     :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                              :min="0" :step="0.01"
			      @change="(val) => {
                                if(!val){
                                  this.form.commission = 0
                                }
                              }"
			     >
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.bonus')" name="bonus">
                      <InputNumber :placeholder="$t('salaryInformation.validate.required.bonus')"
                             v-model:value="form.bonus"
			     :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                              :min="0" :step="0.01"
			      @change="(val) => {
                                if(!val){
                                  this.form.bonus = 0
                                }
                              }"
			     >
                      </InputNumber>
                  </FormItem>
                </Col> -->
                <!-- <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.house_subsidy')" name="houseSubsidy">
                      <InputNumber
                              v-model:value="form.houseSubsidy"
                              :placeholder="$t('salaryInformation.validate.required.house_subsidy')"
                              :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                              :min="0" :step="0.01"
                              style="width:120px"
                              @change="(val) => {
                                if(!val){
                                  this.form.houseSubsidy = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.saturday_overtime_multiples')" name="saturdayOvertimeMultiples">
                      <InputNumber
                              v-model:value="form.saturdayOvertimeMultiples"
                              :placeholder="$t('salaryInformation.validate.required.saturday_overtime_multiples')"
                              :step="0.1"
                              @change="(val) => {
                                if(!val){
                                  this.form.saturdayOvertimeMultiples = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.sunday_overtime_multiples')" name="sundayOvertimeMultiples">
                      <InputNumber
                              v-model:value="form.sundayOvertimeMultiples"
                              :placeholder="$t('salaryInformation.validate.required.sunday_overtime_multiples')"
                              :step="0.1"
                              @change="(val) => {
                                if(!val){
                                  this.form.sundayOvertimeMultiples = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.holiday_overtime_multiples')" name="holidayOvertimeMultiples">
                      <InputNumber
                              v-model:value="form.holidayOvertimeMultiples"
                              :placeholder="$t('salaryInformation.validate.required.holiday_overtime_multiples')"
                              :step="0.1"
                              @change="(val) => {
                                if(!val){
                                  this.form.holidayOvertimeMultiples = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.normal_overtime_multiples')" name="normalOvertimeMultiples">
                      <InputNumber
                              v-model:value="form.normalOvertimeMultiples"
                              :placeholder="$t('salaryInformation.validate.required.normal_overtime_multiples')"
                              :step="0.1"
                              @change="(val) => {
                                if(!val){
                                  this.form.normalOvertimeMultiples = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.remark')" name="remark">
                      <Textarea
                              v-model:value="form.remark"
                              :placeholder="$t('salaryInformation.validate.required.remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.create_time')" name="createTime">
                      <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.createTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col> 
                <Col span="10">
                    <FormItem :label="$t('salaryInformation.field.expire_time')" name="expireTime">
                      <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.expireTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col>-->
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { SalaryInformationAdd } from "@/api/SalaryInformationAdd";
import { SalaryInformationEdit } from "@/api/SalaryInformationEdit";
import { EmployeeListAll } from "@/api/EmployeeListAll"


export default {
  name: "SalaryInformationEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idSalInf : "",
        idEmp : "",
        baseSalary : 0,
        allowance : 0,
        carSubsidy : 0,
        houseSubsidy : 0,
        saturdayOvertimeMultiples : 0,
        sundayOvertimeMultiples : 0,
        holidayOvertimeMultiples : 0,
        normalOvertimeMultiples : 0,
        remark : "",
        createTime : "",
        commission : 0,
        bonus : 0,
      },
      fileList: {
      },
      selectList: {
        employeeList: [],
      },
      validateRules: {
        idSalInf: [
          {
            required: true,
            message: this.$t("salaryInformation.validate.required.id_sal_inf"),
            trigger: "change",
            type: 'number',
          },
        ],
        idEmp: [
          {
            required: true,
            message: this.$t("salaryInformation.validate.required.id_emp"),
            trigger: "change",
            type: 'number',
          },
        ],
        baseSalary: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.base_salary"),
            trigger: "change",
            type: 'number',
          },
        ],
        allowance: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.allowance"),
            trigger: "change",
            type: 'number',
          },
        ],
        carSubsidy: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.car_subsidy"),
            trigger: "change",
            type: 'number',
          },
        ],
        houseSubsidy: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.house_subsidy"),
            trigger: "change",
            type: 'number',
          },
        ],
        saturdayOvertimeMultiples: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.saturday_overtime_multiples"),
            trigger: "change",
            type: 'number',
          },
        ],
        sundayOvertimeMultiples: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.sunday_overtime_multiples"),
            trigger: "change",
            type: 'number',
          },
        ],
        holidayOvertimeMultiples: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.holiday_overtime_multiples"),
            trigger: "change",
            type: 'number',
          },
        ],
        normalOvertimeMultiples: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.normal_overtime_multiples"),
            trigger: "change",
            type: 'number',
          },
        ],
        remark: [
          {
            required: false,
            message: this.$t("salaryInformation.validate.required.remark"),
            trigger: "change",
          },
        ],
        createTime: [
          {
            required: true,
            message: this.$t("salaryInformation.validate.required.create_time"),
            trigger: "change",
          },
        ],
        expireTime: [
          {
            required: true,
            message: this.$t("salaryInformation.validate.required.expire_time"),
            trigger: "change",
          },
        ],
        commission: [
          {
            required: true,
            message: this.$t("salaryInformation.validate.required.commission"),
            trigger: "change",
            type: 'number',
          },
        ],
        bonus: [
          {
            required: true,
            message: this.$t("salaryInformation.validate.required.bonus"),
            trigger: "change",
            type: 'number',
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getEmployeeList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getEmployeeList();
      this.visible = true;
    },
    submit() {
      this.$refs["salaryInformationForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new SalaryInformationAdd();
              break;
            case "edit":
              console.log("edit");
              api = new SalaryInformationEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getEmployeeList() {
      const api = new EmployeeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.employeeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        idSalInf : "",
        idEmp : "",
        baseSalary : 0,
        allowance : 0,
        carSubsidy : 0,
        houseSubsidy : 0,
        saturdayOvertimeMultiples : 0,
        sundayOvertimeMultiples : 0,
        holidayOvertimeMultiples : 0,
        normalOvertimeMultiples : 0,
        remark : "",
        createTime : "",
        commission : 0,
        bonus : 0,
      };
    },
  },
};
</script>

<style lang="less">
</style>