<template>
  <Modal id="CompanyDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('overtimeApplication.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
        <DescriptionsItem :label="$t('overtimeApplication.field.id_ove_app')">{{data.idOveApp}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.id_emp')">{{data.employeeNickName}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.submit_time')">{{data.submitTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.reason')">{{data.reason}}</DescriptionsItem>
<DescriptionsItem :label="$t('overtimeApplication.field.status')">{{selectList.statusList.find(item=>item.value===data.status).label}}</DescriptionsItem>
        <DescriptionsItem ></DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.start_time')">{{data.startTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('overtimeApplication.field.time_remark')">{{data.timeRemark}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.duration')">{{duration}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.approver_id')">{{data.approverNickName}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.approve_time')">{{data.approveTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('overtimeApplication.field.reject_reason')">{{data.rejectReason}}</DescriptionsItem>
  
      <DescriptionsItem :label="$t('overtimeApplication.field.approver2_id')">{{data.approver2NickName}}</DescriptionsItem>
      <DescriptionsItem :label="$t('overtimeApplication.field.approver2_time')">{{data.approver2Time}}</DescriptionsItem>
        <!-- <DescriptionsItem :label="$t('overtimeApplication.field.end_time')">{{data.endTime}}</DescriptionsItem> -->




      <!-- <DescriptionsItem v-if="data.timeFrame" :label="$t('overtimeApplication.field.time_frame')">{{selectList.timeFrameList.find(item=>item.value===data.timeFrame).label}}</DescriptionsItem> -->
      <!-- <DescriptionsItem v-if="data.timeUnit" :label="$t('overtimeApplication.field.time_unit')">{{selectList.timeUnitList.find(item=>item.value===data.timeUnit).label}}</DescriptionsItem> -->
      


  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "OvertimeApplicationDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
        timeFrameList: [{value: 1,label: 'AM'},{value: 2,label: 'PM'}],
        timeUnitList: [{value: 0.25,label: '0.25'},{value: 0.50,label: '0.50'},{value: 1.00,label: '1.00'}],
        statusList: [{value: 0,label: '待發送'},{value: 1,label: '待審批'},{value: 2,label: '已拒絕'},{value: 3,label: '已通過'}],
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
    duration: function () {
      // var days = Math.floor(this.data.duration / 480);
      // var hours = Math.floor((this.data.duration % 480) / 60);
      // var minutes = this.data.duration % 60;
      // var result = "";
      // if (days !== 0) {
      //   result = days + "天";
      // }
      // if (hours !== 0) {
      //   result = result + hours + "小時";
      // }
      // if (minutes !== 0) {
      //   result = result + minutes + "分鐘";
      // }
      return this.data.duration + "小時";
    },
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>