<template>
  <Modal
    id="DownloadLeaveReport"
    width="70%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :confirm-loading="confirmLoading"
    :title="$t('downloadLeaveReport.name.title')"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="downloadLeaveReportForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('leave.field.id_emp')">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idEmp"
                              :placeholder="$t('leave.validate.required.id_emp')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              @change="changeEmp"
                      >
                      <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nickName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('leave.field.id_lea_typ')" name="idLeaTyp">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idLeaTyp"
                              :placeholder="$t('leave.validate.required.id_lea_typ')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                                            <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['leaveTypeList']" :value="item['idLeaTyp']">{{ item['leaveTypeName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                <FormItem :label="$t('attendance.name.dateRange')">
                  <RangePicker :allowClear="false" v-model:value="rangePickerDate" @change="rangeChange"/>
                </FormItem>
              </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  RangePicker,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { EmployeeListAll } from "@/api/EmployeeListAll"
import { LeaveDownloadLeaveReport } from "@/api/LeaveDownloadLeaveReport";
import moment from "moment";
import { LeaveTypeListAll } from "@/api/LeaveTypeListAll"

export default {
  name: "GccsSetUser",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    RangePicker,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      actionType: "",
      rangePickerDate:[moment(),moment()],
      form: {
        idEmp:null,
        idLeaTyp: null,
        startDate: null,
        endDate: null,
      },
      fileList: {},
      selectList: {
        employeeList: [],
        leaveTypeList: [],
      },
      validateRules: {
        idEmp: [
          {
            required: true,
            message: this.$t("leave.validate.required.id_emp"),
            trigger: "change",
            type: "number",
          },
        ],
        dateRange: [
          {
            required: true,
            message: this.$t("downloadLeaveReport.validate.required.date_range"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    async show() {
      this._resetData();
      this.getEmployeeList();
      this.getLeaveTypeList();
      this.visible = true;
    },
    submit() {
      this.$refs["downloadLeaveReportForm"]
        .validate()
        .then(() => {
          const api = new LeaveDownloadLeaveReport();
          api.download(this.form).then((response) => {
            if (!response) {
              return
            }
            if(response.data.type == 'application/json'){
              message.error(this.$t("public.name.no_data"))
              return
            }
            let url = window.URL.createObjectURL(new Blob([response.data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', 'leaveReport.pdf')
            document.body.appendChild(link)
            link.click()
          });
        })
        .catch(() => {});
    },
    rangeChange(dates){
      this.form.startDate = dates[0].format("YYYY-MM-DD")
      this.form.endDate = dates[1].format("YYYY-MM-DD")
    },
    getEmployeeList() {
      const api = new EmployeeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.employeeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    getLeaveTypeList() {
      const api = new LeaveTypeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.leaveTypeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.rangePickerDate = [moment(),moment()],
      this.form = {
        idEmp: null,
        idLeaTyp: null,
        startDate: null,
        endDate: null,
      };
    },
  },

};
</script>

<style lang="less">
</style>