<template>
  <Modal id="CompanyDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('holiday.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
        <DescriptionsItem :label="$t('holiday.field.id_hol')">{{data.idHol}}</DescriptionsItem>
        <DescriptionsItem :label="$t('holiday.field.holiday_name')">{{data.holidayName}}</DescriptionsItem>
        <DescriptionsItem :label="$t('holiday.field.start_date')">{{data.startDate}}</DescriptionsItem>
        <DescriptionsItem :label="$t('holiday.field.end_date')">{{data.endDate}}</DescriptionsItem>
        <DescriptionsItem :label="$t('holiday.field.remark')">{{data.remark}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "HolidayDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>