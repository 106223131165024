<template>
  <Modal id="CompanyDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('salaryInformation.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('salaryInformation.field.id_sal_inf')">{{data.idSalInf}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.id_emp')">{{data.employeeNameTw}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.base_salary')">{{'$' + this.money(data.baseSalary)}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.allowance')">{{'$' + this.money(data.allowance)}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('salaryInformation.field.car_subsidy')">{{'$' + data.carSubsidy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.house_subsidy')">{{'$' + data.houseSubsidy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</DescriptionsItem> -->
      <DescriptionsItem :label="$t('salaryInformation.field.saturday_overtime_multiples')">{{data.saturdayOvertimeMultiples}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.sunday_overtime_multiples')">{{data.sundayOvertimeMultiples}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.holiday_overtime_multiples')">{{data.holidayOvertimeMultiples}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.normal_overtime_multiples')">{{data.normalOvertimeMultiples}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.remark')">{{data.remark}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.create_time')">{{data.createTime}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.expire_time')">{{data.expireTime}}</DescriptionsItem>
      <!-- <DescriptionsItem :label="$t('salaryInformation.field.commission')">{{'$' + data.commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</DescriptionsItem>
      <DescriptionsItem :label="$t('salaryInformation.field.bonus')">{{'$' + data.bonus.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}}</DescriptionsItem> -->
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "SalaryInformationDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
        employeeList: [],
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>