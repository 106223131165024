<template>
  <Modal
    id="AnnualLeavePlanEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="annualLeavePlanForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('annualLeavePlan.field.plan_name')" name="planName">
                      <Input :placeholder="$t('annualLeavePlan.validate.required.plan_name')"
                             v-model:value="form.planName">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('annualLeavePlan.field.annual_leave_days')" name="annualLeaveDays">
                      <InputNumber :placeholder="$t('annualLeavePlan.validate.required.annual_leave_days')"
                             v-model:value="form.annualLeaveDays" 
                             :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')"
                          @change="(val) => {
                                if(!val){
                                  this.form.annualLeaveDays = 0
                                }
                              }">
                      </InputNumber>
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('annualLeavePlan.field.maternity_leave_days')" name="maternityLeaveDays">
                      <InputNumber :placeholder="$t('annualLeavePlan.validate.required.maternity_leave_days')"
                             v-model:value="form.maternityLeaveDays"
                             :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('annualLeavePlan.field.paternity_leave_days')" name="paternityLeaveDays">
                      <InputNumber :placeholder="$t('annualLeavePlan.validate.required.paternity_leave_days')"
                             v-model:value="form.paternityLeaveDays"
                             :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')">
                      </InputNumber>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('annualLeavePlan.field.annual_leave_days_year_imcrement')" name="annualLeaveDaysYearImcrement">
                      <InputNumber :placeholder="$t('annualLeavePlan.validate.required.annual_leave_days_year_imcrement')"
                             v-model:value="form.annualLeaveDaysYearImcrement"
                             :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')"
                          @change="(val) => {
                                if(!val){
                                  this.form.annualLeaveDaysYearImcrement = 0
                                }
                              }"
                          >
                      </InputNumber>
                  </FormItem>
                </Col>
		<Col span="10">
                    <FormItem :label="$t('annualLeavePlan.field.max_annual_leave_days')" name="maxAnnualLeaveDays">
                      <InputNumber :placeholder="$t('annualLeavePlan.validate.required.max_annual_leave_days')"
                             v-model:value="form.maxAnnualLeaveDays"
			     :formatter="value => value + '天'"
                          :parser="value => value.replace('天', '')"
                          @change="(val) => {
                                if(!val){
                                  this.form.maxAnnualLeaveDays = 0
                                }
                              }"
			     >
                      </InputNumber>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { AnnualLeavePlanAdd } from "@/api/AnnualLeavePlanAdd";
import { AnnualLeavePlanEdit } from "@/api/AnnualLeavePlanEdit";


export default {
  name: "AnnualLeavePlanEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idAnnLeaPla : "",
        planName : "",
        annualLeaveDays : 0,
        maternityLeaveDays : 0,
        paternityLeaveDays : 0,
        annualLeaveDaysYearImcrement : 0,
	maxAnnualLeaveDays : 0,
      },
      fileList: {
      },
      selectList: {
      },
      validateRules: {
        idAnnLeaPla: [
          {
            required: true,
            message: this.$t("annualLeavePlan.validate.required.id_ann_lea_pla"),
            trigger: "change",
            type: "number",
          },
        ],
        planName: [
          {
            required: true,
            message: this.$t("annualLeavePlan.validate.required.plan_name"),
            trigger: "change",
          },
        ],
        annualLeaveDays: [
          {
            required: true,
            message: this.$t("annualLeavePlan.validate.required.annual_leave_days"),
            trigger: "change",
            min: 1,
            type: "number",
          },
        ],
        maternityLeaveDays: [
          {
            required: true,
            message: this.$t("annualLeavePlan.validate.required.maternity_leave_days"),
            trigger: "change",
            type: "number",
          },
        ],
        paternityLeaveDays: [
          {
            required: true,
            message: this.$t("annualLeavePlan.validate.required.paternity_leave_days"),
            trigger: "change",
            type: "number",
          },
        ],
        annualLeaveDaysYearImcrement: [
          {
            required: false,
            message: this.$t("annualLeavePlan.validate.required.annual_leave_days_year_imcrement"),
            trigger: "change",
            type: "number",
          },
        ],
        maxAnnualLeaveDays: [
          {
            required: true,
            message: this.$t("annualLeavePlan.validate.required.max_annual_leave_days"),
            trigger: "change",
            type: "number",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
    submit() {
      this.$refs["annualLeavePlanForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new AnnualLeavePlanAdd();
              break;
            case "edit":
              console.log("edit");
              api = new AnnualLeavePlanEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    // reset data
    _resetData() {
      this.form = {
        idAnnLeaPla : "",
        planName : "",
        annualLeaveDays : 0,
        maternityLeaveDays : 0,
        paternityLeaveDays : 0,
        annualLeaveDaysYearImcrement : 0,
	maxAnnualLeaveDays : 0,
      };
    },
  },
};
</script>

<style lang="less">
</style>