<template>
  <Modal
    id="OvertimeApplicationApprove"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="$t('overtimeApplicationApprove.name.title')"
  >
        <Descriptions :title="$t('overtimeApplicationApprove.name.overtimeApplication')">
        <DescriptionsItem :label="$t('overtimeApplication.field.id_ove_app')">{{data.idOveApp}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.id_emp')">{{data.idEmp}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.start_time')">{{data.startTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.end_time')">{{data.endTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.duration')">{{data.duration}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.approver_id')">{{data.approverId}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.approve_time')">{{data.approveTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('overtimeApplication.field.reason')">{{data.reason}}</DescriptionsItem>
  </Descriptions>
  <Descriptions :title="$t('overtimeApplicationApprove.name.historyApplication')"> </Descriptions>
  <Row>
      <Col :span="24">
        <Table
          :columns="columns"
          :dataSource="list"
          :scroll="{ x: true }"
          rowKey="idOveApp"
          :loading="loading"
          :pagination="false"
        >
        </Table>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Descriptions,
  DescriptionsItem,
  Row,
  Col,
  Table,
  Image,
  message,
} from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { OvertimeApplicationApprove } from "@/api/OvertimeApplicationApprove";
import { OvertimeApplicationGetHistoryApplication } from "@/api/OvertimeApplicationGetHistoryApplication";
import moment from "moment";

export default {
  name: "OvertimeApplicationApprove",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
    Image,
    Row,
    Col,
    Table,
    message,
  },
  data() {
    return {
      data: {},
      selectList: {
      },
      visible: false,
      list: [],
      columns: [
          { title: this.$t("overtimeApplication.table.id_ove_app"), fixed: "left", dataIndex: "idOveApp", key: "idOveApp" },
          { title: this.$t("overtimeApplication.table.id_emp"), dataIndex: "employeeNameTw", key: "employeeNameTw" },
          { title: this.$t("overtimeApplication.table.start_time"), dataIndex: "startTime", key: "startTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD HH:mm:ss')}},
          { title: this.$t("overtimeApplication.table.end_time"), dataIndex: "endTime", key: "endTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD HH:mm:ss')}},
          { title: this.$t("overtimeApplication.table.duration"), dataIndex: "duration", key: "duration" },
          { title: this.$t("overtimeApplication.table.approver_id"), dataIndex: "approverId", key: "approverId" },
          { title: this.$t("overtimeApplication.table.approve_time"), dataIndex: "approveTime", key: "approveTime" },
          { title: this.$t("overtimeApplication.table.reason"), dataIndex: "reason", key: "reason" },
        { key: "action", align: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
    };
  },
  computed: {},
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.get_list();
      this.visible = true;
    },
    submit() {
      let api = new OvertimeApplicationApprove();
      api
        .post({
          idOveApp: this.data.idOveApp,
        })
        .then((response, any) => {
          if (0 == parseInt(response.data.code)) {
            message.success(response.data.msg);
            this.$emit("updateList");
            this.visible = false;
          } else {
            message.error(response.data.msg);
          }
        });
    },
    get_list() {
      this.loading = true;
      const api = new OvertimeApplicationGetHistoryApplication();
      const getData = {
        idEmp:this.data.idEmp
      }
      // if (this.searchForm.search != "") getData.search = this.searchForm.search
      // if (this.searchForm.status != 0) getData.status = this.searchForm.status
      // if (this.searchForm.order != "") getData.order = this.searchForm.order
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data;
            this.list = dataTmp.data;
          } else {
            this.list = [];
          }
        });
    },
  },
};
</script>

<style lang="less">
</style>