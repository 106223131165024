<template>
  <Modal
    id="OvertimeApplicationEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="overtimeApplicationForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.id_emp')" name="idEmp">
                      <!--关联表的下拉选择-->
                      <Select
                              disabled
                              v-model:value="form.idEmp"
                              :placeholder="$t('overtimeApplication.validate.required.id_emp')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nickName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.start_time')" name="startTime">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.startTime" :inputReadOnly="true" :popupStyle="{ zIndex: 9999999950 }" @change="changeTimeUnit"></DatePicker>
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.end_time')" name="endTime">
                      <DatePicker :disabledDate="disabledDate" valueFormat="YYYY-MM-DD" v-model:value="form.endTime" :inputReadOnly="true" :popupStyle="{ zIndex: 9999999950 }" @change="changeTimeUnit"></DatePicker>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.reason')" name="reason">
                      <Textarea
                              v-model:value="form.reason"
                              :placeholder="$t('overtimeApplication.validate.required.reason')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.submit_time')" name="submitTime">
                      <DatePicker show-time valueFormat="YYYY-MM-DD HH:mm:ss" v-model:value="form.submitTime" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.time_unit')" name="timeUnit">
                      <Select
                              v-model:value="form.timeUnit"
                              :placeholder="$t('overtimeApplication.validate.required.time_unit')"
                              :options="selectList.timeUnitList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              @change="changeTimeUnit"
                              
                      >
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.duration')" name="duration">
                      <InputNumber :placeholder="$t('overtimeApplication.validate.required.duration')" :step="form.timeUnit" @change="durationChange"
                             v-model:value="form.duration">
                      </InputNumber>
                  </FormItem>
                </Col>
                <!-- <Col span="10" v-if="form.timeUnit === 0.25 || form.timeUnit === 0.50">
                    <FormItem :label="$t('leave.field.time_frame')" name="timeFrame">
                      <Select
                              v-model:value="form.timeFrame"
                              :placeholder="$t('leave.validate.required.time_frame')"
                              :options="selectList.timeFrameList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              
                      >
                      </Select>
                  </FormItem>
                </Col> -->
                <!-- <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.approver_id')" name="approverId">
                      <Select
                              v-model:value="form.approverId"
                              :placeholder="$t('leave.validate.required.approver_id')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nameTw'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col> -->
                <!-- <Col span="10">
                    <FormItem :label="$t('leave.field.status')" name="status">
                      <Select
                              v-model:value="form.status"
                              :placeholder="$t('leave.validate.required.status')"
                              :options="selectList.statusList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('overtimeApplication.field.time_remark')" name="timeRemark">
                      <Textarea
                              v-model:value="form.timeRemark"
                              :placeholder="$t('overtimeApplication.validate.required.time_remark')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { OvertimeApplicationAdd } from "@/api/OvertimeApplicationAdd";
import { OvertimeApplicationEdit } from "@/api/OvertimeApplicationEdit";
import { EmployeeListAll } from "@/api/EmployeeListAll"
import moment from "moment";

export default {
  name: "OvertimeApplicationEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      timeOptions:{
        format:"HH:mm",
        defaultValue: moment('00:00:00', 'HH:mm:ss'),
        minuteStep:30,
      },

      form: {
        idOveApp : "",
        idEmp : this.$store.getters.getId,
        startTime : null,
        endTime : null,
        reason : "",
        submitTime : "",
        timeFrame : "",
        timeUnit : 0.5,
        status : "",
      },
      fileList: {
      },
      selectList: {
        employeeList: [],
        timeFrameList: [{value: 1,label: 'AM'},{value: 2,label: 'PM'}],
        timeUnitList: [{value: 0.25,label: '0.25'},{value: 0.50,label: '0.50'},{value: 1.00,label: '1.00'}],
        statusList: [{value: 0,label: '待發送'},{value: 1,label: '待審批'},{value: 2,label: '已拒絕'},{value: 3,label: '已通過'}],
      },
      validateRules: {
        idOveApp: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.id_ove_app"),
            trigger: "change",
          },
        ],
        idEmp: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.id_emp"),
            trigger: "change",
            type: "number"
          },
        ],
        startTime: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.start_time"),
            trigger: "change",
          },
        ],
        endTime: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.end_time"),
            trigger: "change",
          },
        ],
        duration: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.duration"),
            trigger: "change",
            type: "number",
            min: 0.25,
          },
        ],
        approverId: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.approver_id"),
            trigger: "change",
            type: "number",
          },
        ],
        approveTime: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.approve_time"),
            trigger: "change",
          },
        ],
        reason: [
          {
            required: false,
            message: this.$t("overtimeApplication.validate.required.reason"),
            trigger: "change",
          },
        ],
        submitTime: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.submit_time"),
            trigger: "change",
          },
        ],
        timeFrame: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.time_frame"),
            trigger: "change",
            type: "number",
          },
        ],
        timeUnit: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.time_unit"),
            trigger: "change",
            type: "number",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("overtimeApplication.validate.required.status"),
            trigger: "change",
            type: "number",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getEmployeeList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getEmployeeList();
      this.visible = true;
    },
    submit() {
      this.$refs["overtimeApplicationForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new OvertimeApplicationAdd();
              break;
            case "edit":
              console.log("edit");
              api = new OvertimeApplicationEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current <= moment().startOf('day');
    },
    getEmployeeList() {
      const api = new EmployeeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.employeeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    changeTimeUnit(){
      if(this.form.duration){
        this.form.duration = this.form.duration - this.form.duration % this.form.timeUnit
      }
    },
    durationChange(val){
      this.form.duration = val - val % this.form.timeUnit
    },
    // reset data
    _resetData() {
      this.form = {
        idOveApp : "",
        idEmp : this.$store.getters.getId,
        startTime : "",
        endTime : "",
        reason : "",
        submitTime : "",
        timeFrame : "",
        timeUnit : 0.5,
        status : "",
        isDisableTimeUnit:false,
      };
    },
  },
};
</script>

<style lang="less">
</style>