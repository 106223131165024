<template>
  <Modal id="OvertimeApplicationReject" :visible="visible" @cancel="visible = !visible" @ok="send" :title="$t('overtimeApplicationApprove.name.reject_title')" >
    <Form ref="member_form" :model="form" layout="vertical" :rules="validateRules">
      <Row :gutter="[16,16]">
        <Col :xs="24" :sm="24">
          <FormItem :label="$t('leave.field.reject_reason')" name="rejectReason">
            <Textarea
                              v-model:value="form.rejectReason"
                              :placeholder="$t('leave.validate.required.reject_reason')"
                              :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount
                      />
          </FormItem>
        </Col>
      </Row>
    </Form>
  </Modal>
</template>


<script>
import { Modal, Row, Col, Form, Input, message,Textarea } from "ant-design-vue";
import { OvertimeApplicationReject } from "@/api/OvertimeApplicationReject"

export default {
  name: "OvertimeApplicationReject",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    InputPassword: Input.Password,
    Textarea
  },
  data() {
    return {
      visible: false,
      form: {
        idOveApp: null,
        rejectReason: "",
      },
      validateRules: {
        rejectReason: [
          {
            required: true,
            message: this.$t("leave.validate.required.reject_reason"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async show(idOveApp) {
      this._resetData();
      this.form.idOveApp = idOveApp;
      this.visible = true;
    },
    // send put
    send() {
      let api = new OvertimeApplicationReject();
      this.$refs["member_form"]
        .validate()
        .then(() => {
          
          api
            .post(this.form)
            .then((response) => {
              if (0 == parseInt(response.data.code)) {
                message.success(response.data.msg);
                this.$emit("updateList");
                this.visible = false;
              } else {
                message.error(response.data.msg);
              }
            });
        })
        .catch();
    },
    // reset data
    _resetData() {
      this.form = {
        idOveApp: null,
        rejectReason: "",
      };
    },
  },
}
</script>

<style lang="less">
</style>