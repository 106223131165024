<template>
  <Modal
    id="ReportConfirm"
    width="40%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="$t('reportConfirm.name.title')"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="form"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('reportConfirm.field.pay_date')" name="payDate">
                      <DatePicker valueFormat="YYYY-MM-DD" v-model:value="form.payDate" :popupStyle="{ zIndex: 9999999950 }"></DatePicker>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { ReportConfirm } from "@/api/ReportConfirm"



export default {
  name: "ReportConfirm",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idRep: null,
        payDate : "",
      },
      fileList: {
      },
      selectList: {
      },
      validateRules: {
        payDate: [
          {
            required: true,
            message: this.$t("reportConfirm.validate.required.pay_date"),
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    show(idRep) {
      this.actionType = "confirm";
      this._resetData();
      this.form.idRep = idRep;
      this.visible = true;
    },
    submit() {
      this.$refs["form"]
        .validate()
        .then(() => {
          const api = new ReportConfirm();
            api.post(this.form).then((response) => {
              if (0 === parseInt(response.data.code)) {
                this.$emit("updateList");
                  this.visible = false;
                message.success(response.data.msg);
              } else {
                message.error(response.data.msg);
              }
            });
          
        })
        .catch(() => {
        });
    },
    // reset data
    _resetData() {
      this.form = {
        idRep: null,
        payDate : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>