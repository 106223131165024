<template>
  <Modal
    id="GccsSetUser"
    width="40%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :confirm-loading="confirmLoading"
    :title="$t('gccsSetUser.name.title')"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="gccsUserForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                  <FormItem
                    :label="$t('gccsSetUser.field.device')"
                    name="did"
                  >
                    <Select
                      v-model:value="form.did"
                      :placeholder="
                        $t('gccsSetUser.validate.required.device')
                      "
                      :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    <SelectOption :key="sk" v-for="(item,sk) in selectList['gccsDeviceList']" :value="item['did']">{{ item['device_name'] }}</SelectOption>
                    </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                  <FormItem
                    :label="$t('gccsSetUser.field.user_privilege')"
                    name="user_privilege"
                  >
                    <Select
                      v-model:value="form.user_privilege"
                      :placeholder="
                        $t('gccsSetUser.validate.required.user_privilege')
                      "
                      :options="selectList.userPrivilegeList"
                      :dropdownStyle="{ zIndex: 9999999950 }"
                    >
                    </Select>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { GccsSetUser } from "@/api/GccsSetUser";
import { GccsDeviceList } from "@/api/GccsDeviceList"

export default {
  name: "GccsSetUser",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      actionType: "",
      form: {
        did: null,
        password: "123456",
        idcard: null,
        user_number: null,
        user_privilege: null,
        user_name: null,
      },
      fileList: {},
      selectList: {
        gccsDeviceList:[],
        userPrivilegeList: [
          { value: "MANAGER", label: "管理員" },
          { value: "OPERATOR", label: "操作員" },
          { value: "USER", label: "一般用戶" },
        ],
      },
      validateRules: {
        did: [
          {
            required: true,
            message: this.$t("gccsSetUser.validate.required.device"),
            trigger: "change",
            type: "number",
          },
        ],
        userPrivilege: [
          {
            required: true,
            message: this.$t("gccsSetUser.validate.required.user_privilege"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    async putShow(obj) {
      this._resetData();
      this.getGccsDeviceList();
      this.actionType = "put";
      this.form.idcard = obj.idEmp;
      this.form.user_number = obj.idEmp;
      this.form.user_name = obj.nameTw;
      this.visible = true;
    },
    submit() {
      this.$refs["gccsUserForm"]
        .validate()
        .then(() => {
          let api = new GccsSetUser();
          this.confirmLoading = true;
          api.post(this.form).then((response, any) => {
            
            var realResult = JSON.parse(response.data.result)
            if (0 == parseInt(realResult.code)) {
              message.success(realResult.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(realResult.msg);
            }
            this.confirmLoading = false;
          });
        })
        .catch(() => {});
    },
    getGccsDeviceList() {
      const api = new GccsDeviceList();
      api.get().then((response) => {
        
        if (0 == parseInt(response.data.code)) {
            this.selectList.gccsDeviceList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // reset data
    _resetData() {
      this.form = {
        did: null,
        password: "123456",
        idcard: null,
        user_number: null,
        user_privilege: null,
        user_name: null,
      };
    },
  },
};
</script>

<style lang="less">
</style>