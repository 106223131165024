<template>
  <Modal id="PersonalPassword" :visible="visible" @cancel="visible = !visible" @ok="send" :title="$t('public.name.reset_password')" >
    <Form ref="member_form" :model="data" layout="vertical" :rules="validateRules">
      <Row :gutter="[16,16]">
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('public.name.password')" name="password">
            <InputPassword :placeholder="$t('public.name.password')" v-model:value="data.password"></InputPassword>
          </FormItem>
        </Col>
        <Col :xs="24" :sm="12">
          <FormItem :label="$t('public.name.confirm_password')" name="confirmPassword">
            <InputPassword :placeholder="$t('public.name.confirm_password')" v-model:value="data.confirmPassword"></InputPassword>
          </FormItem>
        </Col>
      </Row>
    </Form>
  </Modal>
</template>


<script>
import { Modal, Row, Col, Form, Input, message } from "ant-design-vue";
import { ChangePassword } from "@/api/ChangePassword"

export default {
  name: "PersonalPassword",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    InputPassword: Input.Password,
  },
  data() {
    return {
      visible: false,
      data: {
        password: "",
        confirmPassword: "",
      },
      validateRules: {
        password: [
          {
            required: true,
            message: this.$t("employee.validate.required.password"),
            pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: this.$t("employee.validate.required.password"),
            pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    async show() {
      this._resetData();
      this.visible = true;
    },
    // send put
    send() {
      let api = new ChangePassword();
      this.$refs["member_form"]
        .validate()
        .then(() => {
          api
            .post(this.data)
            .then((response) => {
              if (0 == parseInt(response.data.code)) {
                message.success(response.data.msg);
                this.visible = false;
                this.$store.dispatch("logout");
                // location.reload();
                this.$router.push({ name: "Login" });
              } else {
                message.error(response.data.msg);
              }
            });
        })
        .catch();
    },
    // reset data
    _resetData() {
      this.data = {
        password: "",
      };
    },
  },
}
</script>

<style lang="less">
</style>