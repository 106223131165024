<template>
  <Modal id="MpfSettingDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('mpfSetting.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
      <DescriptionsItem :label="$t('mpfSetting.field.id_mpf_set')">{{data.idMpfSet}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpfSetting.field.min')">${{this.money(data.min)}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpfSetting.field.max')">${{this.money(data.max)}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpfSetting.field.percentage')">{{data.percentage}}</DescriptionsItem>
      <DescriptionsItem :label="$t('mpfSetting.field.fix_amount')">${{this.money(data.fixAmount)}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "MpfSettingDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>