<template>
  <Modal id="CompanyDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('report.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
        <DescriptionsItem :label="$t('report.field.id_rep')">{{data.idRep}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.id_emp')">{{data.idEmp}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.month')">{{data.month}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.base_salary')">{{data.baseSalary}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.allowance')">{{data.allowance}}</DescriptionsItem>
        <!-- <DescriptionsItem :label="$t('report.field.car_subsidy')">{{data.carSubsidy}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.house_subsidy')">{{data.houseSubsidy}}</DescriptionsItem> -->
        <DescriptionsItem :label="$t('report.field.other_subsidy')">{{data.otherSubsidy}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.commission')">{{data.commission}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.overtime_times')">{{data.overtimeTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.overtime_pay')">{{data.overtimePay}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.overtime')">{{data.overtime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.bonus')">{{data.bonus}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.compensatory_leave_time')">{{data.compensatoryLeaveTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.total_use_annual_leave')">{{data.totalUseAnnualLeave}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.total_annual_leave')">{{data.totalAnnualLeave}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.use_annual_leave')">{{data.useAnnualLeave}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.remark')">{{data.remark}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.leave_time')">{{data.leaveTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.absence_days')">{{data.absenceDays}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.create_time')">{{data.createTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.mpf')">{{data.mpf}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.other_deductions')">{{data.otherDeductions}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.total_salary')">{{data.totalSalary}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.attendance_days')">{{data.attendanceDays}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.rest_days')">{{data.restDays}}</DescriptionsItem>
        <!-- <DescriptionsItem :label="$t('report.field.work_hours')">{{data.workHours}}</DescriptionsItem> -->
        <DescriptionsItem :label="$t('report.field.late_time')">{{data.lateTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.late_times')">{{data.lateTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.serious_late_times')">{{data.seriousLateTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.serious_late_time')">{{data.seriousLateTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.absenteeism_times')">{{data.absenteeismTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.early_leave_time')">{{data.earlyLeaveTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.early_leave_times')">{{data.earlyLeaveTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.no_check_in_times')">{{data.noCheckInTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.no_check_out_times')">{{data.noCheckOutTimes}}</DescriptionsItem>
      <DescriptionsItem :label="$t('report.field.status')">{{selectList.statusList.find(item=>item.value===data.status).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('report.field.need_attendance_days')">{{data.needAttendanceDays}}</DescriptionsItem>
  	<DescriptionsItem :label="$t('report.field.employer_mpf')">{{data.employerMpf}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "ReportDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      selectList:{
        statusList: [{value: 0,label: 'δ�l'},{value: 1,label: '�Ѱl'}],
      },
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>