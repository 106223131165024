<template>
  <Row id="SalaryInformation">
    <Col :span="24" class="title">
      <TypographyTitle>{{$t('nav.name.SalaryInformationManage')}}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16,32]">
        <Col :span="24" >
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('salaryInformation.name.search')" name="search">
                  <Input :placeholder="$t('salaryInformation.name.search')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('employee.name.status')">
                  <Select v-model:value="searchForm.status" :placeholder="$t('public.placeholder.please_select')">
                    <SelectOption :value="null">{{ $t('employee.name.status_all') }}</SelectOption>
                    <SelectOption :value="0">{{ $t('employee.name.status_be_on_the_job') }}</SelectOption>
                    <SelectOption :value="1">{{ $t('employee.name.status_quit') }}</SelectOption>
                  </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('attendance.name.dateRange')" name="dateRange">
                  <RangePicker :allowClear="false" v-model:value="rangePickerDate" @change="rangeChange"/>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8">
                <FormItem :label="$t('attendance.name.department')" name="department">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="searchForm.idDep"
                      >
                      <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['departmentList']" :value="item['idDep']">{{ item['name'] }}</SelectOption>
                      </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{$t('public.name.search')}}</Button>&nbsp;
                <Button @click="search_reset">{{$t('public.name.reset')}}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
        <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idSalInf" :loading="loading" :pagination="tablePagination" @change="chang_page">
            <template #actionTitle>
              <span>
                {{$t("public.table.action")}}
                <Button v-if="checkPermission('SalaryInformationManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button>
              </span>
            </template>
            <template #action="{text}">
              <div>
                <!-- <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('SalaryInformationManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('SalaryInformationManage.Delete')" type="primary" danger size="small" @click="del(text.idSalInf)">{{$t("public.name.del")}}</Button>
                </div> -->
                <Dropdown :trigger="['click']">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="detail(text)">{{$t('public.name.detail')}}</MenuItem>
                      <MenuItem v-if="checkPermission('SalaryInformationManage.Edit')" @click="edit(text)">{{$t('public.name.edit')}}</MenuItem>
                      <MenuItem v-if="checkPermission('SalaryInformationManage.Delete')" danger @click="del(text.idSalInf)">{{$t('public.name.del')}}</MenuItem>
                    </Menu>
                  </template>
                  <Button size="small">
                    {{$t("public.name.more")}}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <SalaryInformationDetail ref="refSalaryInformationDetail"></SalaryInformationDetail>
    <SalaryInformationEdit ref="refSalaryInformationEdit" @updateList="get_list"></SalaryInformationEdit>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message,RangePicker } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { SalaryInformationList } from "@/api/SalaryInformationList";
import { SalaryInformationDelete } from "@/api/SalaryInformationDelete"
import { DepartmentListAll } from "@/api/DepartmentListAll";
import SalaryInformationDetail from "@/components/Home/SalaryInformationDetail";
import SalaryInformationEdit from "@/components/Home/SalaryInformationEdit";
import moment from "moment";

export default {
  name: "SalaryInformationManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    SalaryInformationDetail,
    SalaryInformationEdit,
    RangePicker,
  },
  data() {
    return {
      rangePickerDate:[null,null],
      searchForm: {
        search: "",
        status: 0,
        startDate: null,
        endDate: null,
        idDep: null,
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
          { title: this.$t("salaryInformation.table.id_sal_inf"), fixed: "left", dataIndex: "idSalInf", key: "idSalInf" },
          { title: this.$t("salaryInformation.table.id_emp"), dataIndex: "employeeNickName", key: "employeeNickName" },
          { title: this.$t("salaryInformation.table.base_salary"), dataIndex: "baseSalary", key: "baseSalary", customRender: ({text}) =>{
              return '$' + this.money(text)
            }  },
          { title: this.$t("salaryInformation.table.allowance"), dataIndex: "allowance", key: "allowance", customRender: ({text}) =>{
              return '$' + this.money(text)
            }  },
          //   { title: this.$t("salaryInformation.table.commission"), dataIndex: "commission", key: "commission", customRender: ({text}) =>{
          //     return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          //   } },
          // { title: this.$t("salaryInformation.table.bonus"), dataIndex: "bonus", key: "bonus", customRender: ({text}) =>{
          //     return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          //   } },
          // { title: this.$t("salaryInformation.table.car_subsidy"), dataIndex: "carSubsidy", key: "carSubsidy", customRender: ({text}) =>{
          //     return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          //   }  },
          // { title: this.$t("salaryInformation.table.house_subsidy"), dataIndex: "houseSubsidy", key: "houseSubsidy", customRender: ({text}) =>{
          //     return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          //   }  },
          { title: this.$t("salaryInformation.table.saturday_overtime_multiples"), dataIndex: "saturdayOvertimeMultiples", key: "saturdayOvertimeMultiples" },
          { title: this.$t("salaryInformation.table.sunday_overtime_multiples"), dataIndex: "sundayOvertimeMultiples", key: "sundayOvertimeMultiples" },
          { title: this.$t("salaryInformation.table.holiday_overtime_multiples"), dataIndex: "holidayOvertimeMultiples", key: "holidayOvertimeMultiples" },
          { title: this.$t("salaryInformation.table.normal_overtime_multiples"), dataIndex: "normalOvertimeMultiples", key: "normalOvertimeMultiples" },
          { title: this.$t("salaryInformation.table.remark"), dataIndex: "remark", key: "remark" },
          { title: this.$t("salaryInformation.table.status"), dataIndex: "expireTime", key: "expireTime", customRender: ({text}) =>{
              if(text){
                return '失效'
              }else{
                return '使用中'
              }
            }
          },
          { title: this.$t("salaryInformation.table.create_time"), dataIndex: "createTime", key: "createTime", customRender: ({text}) =>{return moment(text).format('YYYY-MM-DD HH:mm:ss')}},
          { title: this.$t("salaryInformation.table.expire_time"), dataIndex: "expireTime", key: "expireTime", customRender: ({text}) =>{
              if(text){
                return moment(text).format('YYYY-MM-DD HH:mm:ss')
              }else{
                return ''
              }
              
            }
          },
	  
          { key: "action", fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList:{
        employeeList: [],
        departmentList: [],
      }
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new SalaryInformationList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      getData.status = this.searchForm.status
      if (this.searchForm.idDep != "") getData.idDep = this.searchForm.idDep
      if (this.searchForm.startDate != "") getData.startDate = this.searchForm.startDate
      if (this.searchForm.endDate != "") getData.endDate = this.searchForm.endDate
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
            //如果當前處於不是第一頁，但是又沒有數據的情況，則把頁面設置為1，並重新請求
            if(this.list.length == 0 && this.tablePagination.current != 1){
              this.tablePagination.current = 1;
              this.get_list();
            }
          } else {
            this.list = [];
          }
        });
    },
    add() {
      this.$refs.refSalaryInformationEdit.addShow();
    },
    detail(obj) {
      this.$refs.refSalaryInformationDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refSalaryInformationEdit.editShow(obj);
    },
    del(idSalInf) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new SalaryInformationDelete();
          api.post({ idSalInf: idSalInf }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.searchForm = {
        search: "",
        status: 0,
        startDate: null,
        endDate: null,
        idDep: null,
      };
      this.rangePickerDate = [null,null];
      this.get_list()
    },
    rangeChange(dates){
      this.searchForm.startDate = dates[0].format("YYYY-MM-DD")
      this.searchForm.endDate = dates[1].format("YYYY-MM-DD")
    },
    getDepartmentList() {
      const api = new DepartmentListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.departmentList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
  },
  mounted() {
    this.get_list();
    this.getDepartmentList();
  },
}
</script>

<style lang="less">
@import url("~@/assets/less/home/salaryInformation.less");
</style>