<template>
  <Modal
    id="MpfSettingEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
    :zIndex="9999999900"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="mpfSettingForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('mpfSetting.field.min')" name="min">
                      <InputNumber :placeholder="$t('mpfSetting.validate.required.min')"
                             v-model:value="form.min"
                             :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                              :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                             >
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('mpfSetting.field.max')" name="max">
                      <InputNumber :placeholder="$t('mpfSetting.validate.required.max')"
                             v-model:value="form.max"
                             :formatter="value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('mpfSetting.field.caculate_method')" name="caculateMethod">
                      <Select
                              v-model:value="form.caculateMethod"
                              :placeholder="$t('mpfSetting.validate.required.caculate_method')"
                              :options="selectList.caculateMethodList"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                      >
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10" v-if="form.caculateMethod === 0">
                    <FormItem :label="$t('mpfSetting.field.percentage')" name="percentage">
                      <InputNumber :placeholder="$t('mpfSetting.validate.required.percentage')"
                             v-model:value="form.percentage"
                             :min="0"
      :max="100"
      :formatter="value => `${value}%`"
      :parser="value => value.replace('%', '')">
                      </InputNumber>
                  </FormItem>
                </Col>
		<Col span="10" v-if="form.caculateMethod === 1">
                    <FormItem :label="$t('mpfSetting.field.fix_amount')" name="fixAmount">
                      <!-- <Input :placeholder="$t('mpfSetting.validate.required.fix_amount')"
                             v-model:value="form.fixAmount">
                      </Input> -->
                      <InputNumber
                              v-model:value="form.fixAmount"
                              :placeholder="$t('mpfSetting.validate.required.fix_amount')"
                              :step="0.1"
                              @change="(val) => {
                                if(!val){
                                  this.form.normalOvertimeMultiples = 0
                                }
                              }"
                      />
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { MpfSettingAdd } from "@/api/MpfSettingAdd";
import { MpfSettingEdit } from "@/api/MpfSettingEdit";


export default {
  name: "MpfSettingEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idMpfSet : "",
        min : "",
        max : "",
        percentage : "",
        caculateMethod: 0,
      },
      fileList: {
      },
      selectList: {
        caculateMethodList:[{value: 0,label: '百分比'},{value: 1,label: '固定金額'}],
      },
      validateRules: {
        idMpfSet: [
          {
            required: true,
            message: this.$t("mpfSetting.validate.required.id_mpf_set"),
            trigger: "change",
            type: "number",
          },
        ],
        min: [
          {
            required: true,
            message: this.$t("mpfSetting.validate.required.min"),
            trigger: "change",
            type: "number",
          },
        ],
        max: [
          {
            required: false,
            message: this.$t("mpfSetting.validate.required.max"),
            trigger: "change",
            type: "number",
          },
        ],
        percentage: [
          {
            required: false,
            message: this.$t("mpfSetting.validate.required.percentage"),
            trigger: "change",
	    type: "number",
          },
        ],
        fixAmount: [
          {
            required: false,
            message: this.$t("mpfSetting.validate.required.fix_amount"),
            trigger: "change",
	    type: "number",
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
    submit() {
      this.$refs["mpfSettingForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new MpfSettingAdd();
              break;
            case "edit":
              console.log("edit");
              api = new MpfSettingEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    // reset data
    _resetData() {
      this.form = {
        idMpfSet : "",
        min : "",
        max : "",
        percentage : "",
        caculateMethod: 0,
      };
    },
  },
};
</script>

<style lang="less">
</style>