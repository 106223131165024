/**
 * @Auther    william
 * @Name      state action function
 * @Note      
 * @Time      2021/04/13 16:10
 */

let actions = {
    // logout function
    logout( {commit} ) {
        commit("logout")
    }
}

export default actions