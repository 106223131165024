<template>
  <Modal id="CompanyDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('attendance.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
        <DescriptionsItem :label="$t('attendance.field.id_att')">{{data.idAtt}}</DescriptionsItem>
        <DescriptionsItem :label="$t('attendance.field.id_emp')">{{data.idEmp}}</DescriptionsItem>
        <DescriptionsItem :label="$t('attendance.field.check_in')">{{data.checkIn}}</DescriptionsItem>
        <DescriptionsItem :label="$t('attendance.field.check_out')">{{data.checkOut}}</DescriptionsItem>
        <DescriptionsItem :label="$t('attendance.field.late')">{{selectList.booleanList.find(item=>item.value===data.late).label}}</DescriptionsItem>
        <DescriptionsItem :label="$t('attendance.field.early_leave')">{{selectList.booleanList.find(item=>item.value===data.earlyLeave).label}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "AttendanceDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      data: {
      },
      visible: false,
      selectList:{
        booleanList:[{value: false,label: '否'},{value: true,label: '是'}],
      }
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      if(!this.data.late){
        this.data.late = false
      }
      if(!this.data.earlyLeave){
        this.data.earlyLeave = false
      }
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>