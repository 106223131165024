<template>
  <Modal
    id="ReportEdit"
    width="80%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :title="title"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="reportForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                    <FormItem :label="$t('report.field.id_emp')" name="idEmp">
                      <!--关联表的下拉选择-->
                      <Select
                              v-model:value="form.idEmp"
                              :placeholder="$t('report.validate.required.id_emp')"
                              :dropdownStyle="{ zIndex: 9999999950 }"
                              disabled
                      >
                          <SelectOption :key="sk" v-for="(item,sk) in selectList['employeeList']" :value="item['idEmp']">{{ item['nickName'] }}</SelectOption>
                      </Select>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.month')" name="month">
                      <Input :placeholder="$t('report.validate.required.month')" disabled
                             v-model:value="form.month">
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.base_salary')" name="baseSalary">
                      <InputNumber :placeholder="$t('report.validate.required.base_salary')"
                            v-model:value="form.baseSalary"
                            :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary"
                            
                            >
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.allowance')" name="allowance">
                      <InputNumber :placeholder="$t('report.validate.required.allowance')"
                             v-model:value="form.allowance"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('report.field.car_subsidy')" name="carSubsidy">
                      <InputNumber :placeholder="$t('report.validate.required.car_subsidy')"
                             v-model:value="form.carSubsidy"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.house_subsidy')" name="houseSubsidy">
                      <InputNumber :placeholder="$t('report.validate.required.house_subsidy')"
                             v-model:value="form.houseSubsidy"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('report.field.other_subsidy')" name="otherSubsidy">
                      <InputNumber :placeholder="$t('report.validate.required.other_subsidy')"
                             v-model:value="form.otherSubsidy"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.commission')" name="commission">
                      <InputNumber :placeholder="$t('report.validate.required.commission')"
                             v-model:value="form.commission"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.overtime_times')" name="overtimeTimes">
                      <Input :placeholder="$t('report.validate.required.overtime_times')"
                             v-model:value="form.overtimeTimes"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.overtime_pay')" name="overtimePay">
                      <InputNumber :placeholder="$t('report.validate.required.overtime_pay')"
                             v-model:value="form.overtimePay"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.overtime')" name="overtime">
                      <Input :placeholder="$t('report.validate.required.overtime')"
                             v-model:value="form.overtime"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.bonus')" name="bonus">
                      <InputNumber :placeholder="$t('report.validate.required.bonus')"
                             v-model:value="form.bonus"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('report.field.compensatory_leave_time')" name="compensatoryLeaveTime">
                      <Input :placeholder="$t('report.validate.required.compensatory_leave_time')"
                             v-model:value="form.compensatoryLeaveTime"
                             disabled>
                      </Input>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('report.field.total_use_annual_leave')" name="totalUseAnnualLeave">
                      <Input :placeholder="$t('report.validate.required.total_use_annual_leave')"
                             v-model:value="form.totalUseAnnualLeave"
                            disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.total_annual_leave')" name="totalAnnualLeave">
                      <Input :placeholder="$t('report.validate.required.total_annual_leave')"
                             v-model:value="form.totalAnnualLeave"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.use_annual_leave')" name="useAnnualLeave">
                      <Input :placeholder="$t('report.validate.required.use_annual_leave')"
                             v-model:value="form.useAnnualLeave"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.remark')" name="remark">
                      <Textarea :placeholder="$t('report.validate.required.remark')"
                             v-model:value="form.remark"
                             :auto-size="{ minRows: 5, maxRows: 10 }"
                              showCount>
                      </Textarea>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.leave_time')" name="leaveTime">
                      <Input :placeholder="$t('report.validate.required.leave_time')"
                             v-model:value="form.leaveTime"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.absence_days')" name="absenceDays">
                      <Input :placeholder="$t('report.validate.required.absence_days')"
                             v-model:value="form.absenceDays"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('report.field.create_time')" name="createTime">
                      <Input :placeholder="$t('report.validate.required.create_time')"
                             v-model:value="form.createTime">
                      </Input>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('report.field.mpf')" name="mpf">
                      <InputNumber :placeholder="$t('report.validate.required.mpf')"
                             v-model:value="form.mpf"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col>
		<Col span="10">
                    <FormItem :label="$t('report.field.employer_mpf')" name="employerMpf">
                      <InputNumber :placeholder="$t('report.validate.required.employer_mpf')"
                             v-model:value="form.employerMpf"
			      :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
			    >
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.other_deductions')" name="otherDeductions">
                      <InputNumber :placeholder="$t('report.validate.required.other_deductions')"
                             v-model:value="form.otherDeductions"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            @change="caculateTotalSalary">
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.total_salary')" name="totalSalary">
                      <InputNumber :placeholder="$t('report.validate.required.total_salary')"
                             v-model:value="form.totalSalary"
                             :formatter="value => ('$'+value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                            :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                            disabled>
                      </InputNumber>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.attendance_days')" name="attendanceDays">
                      <Input :placeholder="$t('report.validate.required.attendance_days')"
                             v-model:value="form.attendanceDays"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.rest_days')" name="restDays">
                      <Input :placeholder="$t('report.validate.required.rest_days')"
                             v-model:value="form.restDays"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <!-- <Col span="10">
                    <FormItem :label="$t('report.field.work_hours')" name="workHours">
                      <Input :placeholder="$t('report.validate.required.work_hours')"
                             v-model:value="form.workHours"
                             disabled>
                      </Input>
                  </FormItem>
                </Col> -->
                <Col span="10">
                    <FormItem :label="$t('report.field.late_time')" name="lateTime">
                      <Input :placeholder="$t('report.validate.required.late_time')"
                             v-model:value="form.lateTime"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.late_times')" name="lateTimes">
                      <Input :placeholder="$t('report.validate.required.late_times')"
                             v-model:value="form.lateTimes"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.serious_late_times')" name="seriousLateTimes">
                      <Input :placeholder="$t('report.validate.required.serious_late_times')"
                             v-model:value="form.seriousLateTimes"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.serious_late_time')" name="seriousLateTime">
                      <Input :placeholder="$t('report.validate.required.serious_late_time')"
                             v-model:value="form.seriousLateTime"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.absenteeism_times')" name="absenteeismTimes">
                      <Input :placeholder="$t('report.validate.required.absenteeism_times')"
                             v-model:value="form.absenteeismTimes"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.early_leave_time')" name="earlyLeaveTime">
                      <Input :placeholder="$t('report.validate.required.early_leave_time')"
                             v-model:value="form.earlyLeaveTime"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.early_leave_times')" name="earlyLeaveTimes">
                      <Input :placeholder="$t('report.validate.required.early_leave_times')"
                             v-model:value="form.earlyLeaveTimes"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.no_check_in_times')" name="noCheckInTimes">
                      <Input :placeholder="$t('report.validate.required.no_check_in_times')"
                             v-model:value="form.noCheckInTimes"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
                <Col span="10">
                    <FormItem :label="$t('report.field.no_check_out_times')" name="noCheckOutTimes">
                      <Input :placeholder="$t('report.validate.required.no_check_out_times')"
                             v-model:value="form.noCheckOutTimes"
                             disabled>
                      </Input>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { ReportAdd } from "@/api/ReportAdd";
import { ReportEdit } from "@/api/ReportEdit";
import { EmployeeListAll } from "@/api/EmployeeListAll"


export default {
  name: "ReportEdit",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea:Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      form: {
        idRep : "",
        idEmp : "",
        month : "",
        baseSalary : "",
        allowance : "",
        carSubsidy : "",
        houseSubsidy : "",
        otherSubsidy : "",
        commission : "",
        overtimeTimes : "",
        overtimePay : "",
        overtime : "",
        bonus : "",
        compensatoryLeaveTime : "",
        totalUseAnnualLeave : "",
        totalAnnualLeave : "",
        useAnnualLeave : "",
        remark : "",
        leaveTime : "",
        absenceDays : "",
        createTime : "",
        mpf : "",
        otherDeductions : "",
        totalSalary : "",
        attendanceDays : "",
        restDays : "",
        workHours : "",
        lateTime : "",
        lateTimes : "",
        seriousLateTimes : "",
        seriousLateTime : "",
        absenteeismTimes : "",
        earlyLeaveTime : "",
        earlyLeaveTimes : "",
        noCheckInTimes : "",
        noCheckOutTimes : "",
      },
      fileList: {
      },
      selectList: {
        employeeList: [],
      },
      validateRules: {
        idRep: [
          {
            required: true,
            message: this.$t("report.validate.required.id_rep"),
            trigger: "change",
            type:"number"
          },
        ],
        idEmp: [
          {
            required: true,
            message: this.$t("report.validate.required.id_emp"),
            trigger: "change",
            type:"number"
          },
        ],
        month: [
          {
            required: true,
            message: this.$t("report.validate.required.month"),
            trigger: "change",
          },
        ],
        baseSalary: [
          {
            required: false,
            message: this.$t("report.validate.required.base_salary"),
            trigger: "change",
            type:"number"
          },
        ],
        allowance: [
          {
            required: false,
            message: this.$t("report.validate.required.allowance"),
            trigger: "change",
            type:"number"
          },
        ],
        carSubsidy: [
          {
            required: false,
            message: this.$t("report.validate.required.car_subsidy"),
            trigger: "change",
            type:"number"
          },
        ],
        houseSubsidy: [
          {
            required: false,
            message: this.$t("report.validate.required.house_subsidy"),
            trigger: "change",
            type:"number"
          },
        ],
        otherSubsidy: [
          {
            required: false,
            message: this.$t("report.validate.required.other_subsidy"),
            trigger: "change",
            type:"number"
          },
        ],
        commission: [
          {
            required: false,
            message: this.$t("report.validate.required.commission"),
            trigger: "change",
            type:"number"
          },
        ],
        overtimeTimes: [
          {
            required: true,
            message: this.$t("report.validate.required.overtime_times"),
            trigger: "change",
            type:"number"
          },
        ],
        overtimePay: [
          {
            required: false,
            message: this.$t("report.validate.required.overtime_pay"),
            trigger: "change",
            type:"number"
          },
        ],
        overtime: [
          {
            required: true,
            message: this.$t("report.validate.required.overtime"),
            trigger: "change",
            type:"number"
          },
        ],
        bonus: [
          {
            required: false,
            message: this.$t("report.validate.required.bonus"),
            trigger: "change",
            type:"number"
          },
        ],
        compensatoryLeaveTime: [
          {
            required: true,
            message: this.$t("report.validate.required.compensatory_leave_time"),
            trigger: "change",
            type:"number"
          },
        ],
        totalUseAnnualLeave: [
          {
            required: true,
            message: this.$t("report.validate.required.total_use_annual_leave"),
            trigger: "change",
            type:"number"
          },
        ],
        totalAnnualLeave: [
          {
            required: true,
            message: this.$t("report.validate.required.total_annual_leave"),
            trigger: "change",
            type:"number"
          },
        ],
        useAnnualLeave: [
          {
            required: true,
            message: this.$t("report.validate.required.use_annual_leave"),
            trigger: "change",
            type:"number"
          },
        ],
        remark: [
          {
            required: false,
            message: this.$t("report.validate.required.remark"),
            trigger: "change",
          },
        ],
        leaveTime: [
          {
            required: true,
            message: this.$t("report.validate.required.leave_time"),
            trigger: "change",
            type:"number"
          },
        ],
        absenceDays: [
          {
            required: true,
            message: this.$t("report.validate.required.absence_days"),
            trigger: "change",
            type:"number"
          },
        ],
        createTime: [
          {
            required: true,
            message: this.$t("report.validate.required.create_time"),
            trigger: "change",
          },
        ],
        mpf: [
          {
            required: false,
            message: this.$t("report.validate.required.mpf"),
            trigger: "change",
            type:"number"
          },
        ],
        otherDeductions: [
          {
            required: false,
            message: this.$t("report.validate.required.other_deductions"),
            trigger: "change",
            type:"number"
          },
        ],
        totalSalary: [
          {
            required: true,
            message: this.$t("report.validate.required.total_salary"),
            trigger: "change",
            type:"number"
          },
        ],
        attendanceDays: [
          {
            required: true,
            message: this.$t("report.validate.required.attendance_days"),
            trigger: "change",
            type:"number"
          },
        ],
        restDays: [
          {
            required: true,
            message: this.$t("report.validate.required.rest_days"),
            trigger: "change",
            type:"number"
          },
        ],
        workHours: [
          {
            required: true,
            message: this.$t("report.validate.required.work_hours"),
            trigger: "change",
            type:"number"
          },
        ],
        lateTime: [
          {
            required: true,
            message: this.$t("report.validate.required.late_time"),
            trigger: "change",
            type:"number"
          },
        ],
        lateTimes: [
          {
            required: true,
            message: this.$t("report.validate.required.late_times"),
            trigger: "change",
            type:"number"
          },
        ],
        seriousLateTimes: [
          {
            required: true,
            message: this.$t("report.validate.required.serious_late_times"),
            trigger: "change",
            type:"number"
          },
        ],
        seriousLateTime: [
          {
            required: true,
            message: this.$t("report.validate.required.serious_late_time"),
            trigger: "change",
            type:"number"
          },
        ],
        absenteeismTimes: [
          {
            required: true,
            message: this.$t("report.validate.required.absenteeism_times"),
            trigger: "change",
            type:"number"
          },
        ],
        earlyLeaveTime: [
          {
            required: true,
            message: this.$t("report.validate.required.early_leave_time"),
            trigger: "change",
            type:"number"
          },
        ],
        earlyLeaveTimes: [
          {
            required: true,
            message: this.$t("report.validate.required.early_leave_times"),
            trigger: "change",
            type:"number"
          },
        ],
        noCheckInTimes: [
          {
            required: true,
            message: this.$t("report.validate.required.no_check_in_times"),
            trigger: "change",
            type:"number"
          },
        ],
        noCheckOutTimes: [
          {
            required: true,
            message: this.$t("report.validate.required.no_check_out_times"),
            trigger: "change",
            type:"number"
          },
        ],
      },
    };
  },
  computed: {
    title: function () {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.getEmployeeList();
      this.visible = true;
    },
    async editShow(obj) {
      this._resetData();
      this.actionType = "edit";
      this.form = JSON.parse(JSON.stringify(obj));
      this.getEmployeeList();
      this.visible = true;
    },
    submit() {
      this.$refs["reportForm"]
        .validate()
        .then(() => {
          let api;
          switch (this.actionType) {
            case "add":
              console.log("add");
              api = new ReportAdd();
              break;
            case "edit":
              console.log("edit");
              api = new ReportEdit();
              break;
            default:
              return false;
          }
          api.post(this.form).then((response, any) => {
            if (0 == parseInt(response.data.code)) {
              message.success(response.data.msg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
        })
        .catch(() => {
        });
    },
    getEmployeeList() {
      const api = new EmployeeListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
            this.selectList.employeeList = response.data.data;
        } else {
            message.error(response.data.msg);
        }
      });
    },
    // caculateTotalSalary(){
    //   if(!this.form.baseSalary){
    //     this.form.baseSalary = 0;
    //   }
    //   if(!this.form.allowance){
    //     this.form.allowance = 0;
    //   }
    //   if(!this.form.carSubsidy){
    //     this.form.carSubsidy = 0;
    //   }
    //   if(!this.form.houseSubsidy){
    //     this.form.houseSubsidy = 0;
    //   }
    //   if(!this.form.otherSubsidy){
    //     this.form.otherSubsidy = 0;
    //   }
    //   if(!this.form.commission){
    //     this.form.commission = 0;
    //   }
    //   if(!this.form.overtimePay){
    //     this.form.overtimePay = 0;
    //   }
    //   if(!this.form.bonus){
    //     this.form.bonus = 0;
    //   }
    //   if(!this.form.otherDeductions){
    //     this.form.otherDeductions = 0;
    //   }
    
    //   this.form.totalSalary = this.form.baseSalary + this.form.allowance + this.form.carSubsidy + this.form.houseSubsidy + this.form.otherSubsidy + this.form.commission + this.form.overtimePay + this.form.bonus + this.form.otherDeductions;
    // },

    caculateTotalSalary(){
      
        var realTotalSalary = this.form.baseSalary + this.form.allowance  + this.form.otherSubsidy + this.form.commission + this.form.overtimePay + this.form.bonus + this.form.otherDeductions;
        this.form.totalSalary = realTotalSalary - this.form.mpf;
    },
  

    // reset data
    _resetData() {
      this.form = {
        idRep : "",
        idEmp : "",
        month : "",
        baseSalary : "",
        allowance : "",
        carSubsidy : "",
        houseSubsidy : "",
        otherSubsidy : "",
        commission : "",
        overtimeTimes : "",
        overtimePay : "",
        overtime : "",
        bonus : "",
        compensatoryLeaveTime : "",
        totalUseAnnualLeave : "",
        totalAnnualLeave : "",
        useAnnualLeave : "",
        remark : "",
        leaveTime : "",
        absenceDays : "",
        createTime : "",
        mpf : "",
        otherDeductions : "",
        totalSalary : "",
        attendanceDays : "",
        restDays : "",
        workHours : "",
        lateTime : "",
        lateTimes : "",
        seriousLateTimes : "",
        seriousLateTime : "",
        absenteeismTimes : "",
        earlyLeaveTime : "",
        earlyLeaveTimes : "",
        noCheckInTimes : "",
        noCheckOutTimes : "",
      };
    },
  },
};
</script>

<style lang="less">
</style>