<template>
  <div id="App">
    <ConfigProvider>
      <router-view />
    </ConfigProvider>
  </div>
</template>

<script>
import { ConfigProvider } from "ant-design-vue";

export default {
  name: "App",
  components: {
    ConfigProvider,
  },
  data() {
    return {
    };
  },
  mounted() {
    this.$store.commit("reset");
    switch (this.$store.getters.getLanguage) {
      case "zhTW":
        this.$i18n.locale = "zhTW";
        break;
      case "enUS":
        this.$i18n.locale = "enUS";
        break;
      case "zhCN":
        this.$i18n.locale = "zhCN";
        break;
    }
  },
};
</script>

