<template>
  <Modal
    id="TermReport"
    width="70%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="submit"
    :confirm-loading="confirmLoading"
    :title="$t('employeeEntryRecord.name.term_report')"
  >
    <Row align="middle" justify="center">
      <Col span="18" class="content">
        <Row>
          <Col span="24">
            <Form
              ref="termReportForm"
              :model="form"
              layout="vertical"
              :rules="validateRules"
            >
              <Row justify="space-around">
                <Col span="10">
                <FormItem :label="$t('attendance.name.dateRange')">
                  <RangePicker :allowClear="false" v-model:value="rangePickerDate" @change="rangeChange"/>
                </FormItem>
              </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  </Modal>
</template>

<script>
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Image,
  Radio,
  Steps,
  Step,
  Button,
  message,
  Select,
  DatePicker,
  InputGroup,
  Upload,
  Descriptions,
  DescriptionsItem,
  RangePicker,
} from "ant-design-vue";
import {
  UploadOutlined,
  UserOutlined,
  SolutionOutlined,
  FileImageOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { EmployeeEntryRecordTermReport } from "@/api/EmployeeEntryRecordTermReport";
import moment from "moment";

export default {
  name: "TermReport",
  components: {
    Modal,
    Row,
    Col,
    UserOutlined,
    SolutionOutlined,
    FileImageOutlined,
    CheckOutlined,
    UploadOutlined,
    PlusOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Textarea: Input.TextArea,
    InputGroup,
    InputNumber,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    Steps,
    Step,
    Button,
    message,
    Select,
    SelectOption: Select.Option,
    DatePicker,
    Upload,
    Descriptions,
    DescriptionsItem,
    RangePicker,
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      actionType: "",
      rangePickerDate:[null,null],
      form: {
        startDate: null,
        endDate: null,
      },
      fileList: {},
      selectList: {
        
      },
      validateRules: {
        idEmp: [
          {
            required: true,
            message: this.$t("leave.validate.required.id_emp"),
            trigger: "change",
            type: "number",
          },
        ],
        dateRange: [
          {
            required: true,
            message: this.$t("downloadLeaveReport.validate.required.date_range"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    async show() {
      this._resetData();
      this.visible = true;
    },
    submit() {
      this.$refs["termReportForm"]
        .validate()
        .then(() => {
          const api = new EmployeeEntryRecordTermReport();
          api.download(this.form).then((response) => {
            if (!response) {
              return
            }
            if(response.data.type == 'application/json'){
              message.error(this.$t("public.name.no_data"))
              return
            }
            let url = window.URL.createObjectURL(new Blob([response.data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', 'termReport.pdf')
            document.body.appendChild(link)
            link.click()
          });
        })
        .catch(() => {});
    },
    rangeChange(dates){
      this.form.startDate = dates[0].format("YYYY-MM-DD")
      this.form.endDate = dates[1].format("YYYY-MM-DD")
    },
    // reset data
    _resetData() {
      this.rangePickerDate = [null,null],
      this.form = {
        startDate: null,
        endDate: null,
      };
    },
  },
};
</script>

<style lang="less">
</style>