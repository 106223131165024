<template>
  <Modal id="CompanyDetail" width="60%" :visible="visible" @cancel="visible = !visible" @ok="visible = !visible" :title="$t('remainingVacation.name.detailTitle')" :zIndex="9999999900">
    <Descriptions >
        <DescriptionsItem :label="$t('remainingVacation.field.id_rem_vac')">{{data.idRemVac}}</DescriptionsItem>
        <DescriptionsItem :label="$t('remainingVacation.field.id_emp')">{{data.employeeNickName}}</DescriptionsItem>
        <DescriptionsItem :label="$t('remainingVacation.field.annual_leave_days')">{{data.annualLeaveDays + '天'}}</DescriptionsItem>
        <DescriptionsItem :label="$t('remainingVacation.field.maternity_leave_days')">{{data.maternityLeaveDays + '天'}}</DescriptionsItem>
        <DescriptionsItem :label="$t('remainingVacation.field.paternity_leave_days')">{{data.paternityLeaveDays + '天'}}</DescriptionsItem>
        <DescriptionsItem :label="$t('remainingVacation.field.remaining_annual_leave_days')">{{data.remainingAnnualLeaveDays + '天'}}</DescriptionsItem>
        <DescriptionsItem :label="$t('remainingVacation.field.remaining_maternity_leave_days')">{{data.remainingMaternityLeaveDays + '天'}}</DescriptionsItem>
        <DescriptionsItem :label="$t('remainingVacation.field.remaining_paternity_leave_days')">{{data.remainingPaternityLeaveDays + '天'}}</DescriptionsItem>
        <DescriptionsItem :label="$t('remainingVacation.field.year')">{{data.year + '年'}}</DescriptionsItem>
      <DescriptionsItem :label="$t('remainingVacation.field.remark')">{{data.remark}}</DescriptionsItem>
      <DescriptionsItem :label="$t('remainingVacation.field.remaining_sick_leave_days')">{{data.remainingSickLeaveDays + '天'}}</DescriptionsItem>
  </Descriptions>
  </Modal>
</template>

<script>
import { Modal, Descriptions,DescriptionsItem,Badge } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "RemainingVacationDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
    Badge,
  },
  data() {
    return {
      data: {
      },
      visible: false,
    };
  },
  computed: {
  },
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
}
</script>

<style lang="less">
</style>