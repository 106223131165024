<template>
  <Row id="Login" align="middle">
    <Col class="header" :span="24"></Col>
    <Col class="content" :span="24">
      <Row>
        <Col class="pc-display" :sm="6"></Col>
        <Col class="login-form" :xs="24" :sm="12">
          <Row>
            <Col class="login-form-left" :xs="24" :sm="20">
              <Row>
                <Col class="login-form-left-title" :span="12">{{$t("public.name.login")}}</Col>
                <Col class="login-form-left-language" :span="12">
                  <RadioGroup v-model:value="language" size="small" @change="changeLanguage">
                    <RadioButton value="zhTW">中（繁体）</RadioButton>
                    <RadioButton value="enUS">En</RadioButton>
                    <RadioButton value="zhCN">中（简体）</RadioButton>
                  </RadioGroup>
                </Col>
                <Col class="login-form-left-content" :span="24">
                  <Form ref="refLogin" layout="vertical" :model="login" :rules="validateRules">
                    <FormItem :label="$t('public.name.account')+':'" name="email">
                      <Input :placeholder="$t('public.name.account')" v-model:value="login.email">
                        <template #suffix>
                          <UserOutlined />
                        </template>
                      </Input>
                    </FormItem>
                    <FormItem :label="$t('public.name.password')+':'" name="password">
                      <InputPassword :placeholder="$t('public.name.password')" v-model:value="login.password"></InputPassword>
                    </FormItem>
                    <!-- <FormItem :label="$t('public.name.captcha')+':'" name="captcha">
                      <Input :placeholder="$t('public.name.captcha')" v-model:value="login.captcha_code" @keyup.enter="sendLogin">
                        <template #suffix>
                          <Image :width="150" :height="40" :fallback="$store.getters.getNoImage" :src="captchaImg" :preview="false" @click="getCaptcha"></Image>
                        </template>
                      </Input>
                    </FormItem> -->
                  </Form>
                </Col>
                <Col :span="24" class="wap-display text-center" style="margin-top:10px">
                  <LoginOutlined :style="{ fontSize: '50px' }" @click="sendLogin" />
                </Col>
              </Row>
            </Col>
            <Col class="login-form-right pc-display" :sm="4" @click="sendLogin">
              <LoginOutlined :style="{ fontSize: '50px' }" />
            </Col>
          </Row>
        </Col>
        <Col :sm="6" class="pc-display"></Col>
      </Row>
    </Col>
    <Col class="footer" :span="24">{{ $store.getters.getCopyRight }}</Col>
  </Row>
</template>


<script>
import { Row, Col, Form, Input, Image, Radio, message } from "ant-design-vue";
import { LoginOutlined, UserOutlined } from "@ant-design/icons-vue";
import { Login } from "@/api/Login"

export default {
  name: "Login",
  components: {
    Row,
    Col,
    LoginOutlined,
    UserOutlined,
    Form: Form,
    FormItem: Form.Item,
    Input: Input,
    InputPassword: Input.Password,
    Image,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
  },
  data() {
    return {
      login: {
        email: "",
        password: "",
        // captcha_code: "",
        // captcha_key: "",
      },
      captchaImg: "",
      captchaExpire: 0,
      language: "zhTW",
      validateRules: {
        email: [
          {
            required: true,
            // type: "email",
            message: this.$t("login.validate.required.email"),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("login.validate.required.password"),
            trigger: "blur",
          },
        ],
        // captcha_code: [
        //   {
        //     required: true,
        //     message: this.$t("login.validate.required.captcha"),
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    // get captcha
    getCaptcha() {
      // const api = new Captcha();
      // api.get({ type: 1 }).then((response, any) => {
      //   if (1 == parseInt(response.data.code)) {
      //     this.captchaImg = response.data.data.image;
      //     this.login.captcha_key = response.data.data.key;
      //     this.captchaExpire = parseInt(response.data.data.expire_time);
      //   } else {
      //     this.captchaImg = "";
      //     this.login.captcha_key = "";
      //     this.captchaExpire = 0;
      //   }
      // });
    },
    // change language
    changeLanguage() {
      switch (this.language) {
        case "zhTW":
          this.$i18n.locale = "zhTW";
          this.$store.commit("setLanguage", "zhTW");
          break;
        case "enUS":
          this.$i18n.locale = "enUS";
          this.$store.commit("setLanguage", "enUS");
          break;
        case "zhCN":
          this.$i18n.locale = "zhCN";
          this.$store.commit("setLanguage", "zhCN");
          break;
      }
    },
    // send login message
    sendLogin() {
      this.$refs["refLogin"]
          .validate()
          .then(() => {
            const api = new Login();
            api.post(this.login).then((response, any) => {
              if (0 == parseInt(response.data.code)) {
                this.$store.commit("setId", response.data.data.id);
                this.$store.commit("setToken", response.data.data.token);
                this.$store.commit("setUsername", response.data.data.name);
                this.$store.commit("setPermissionList", response.data.data.permissionList);
                // this.$store.commit("setEmail", response.data.data.email);
                // this.$store.commit("setLogin", response.data.data.login);
                // this.$store.commit("setPhone", response.data.data.phone);
                // this.$store.commit("setType", response.data.data.type);
                if(response.data.msg != null && response.data.msg !== ''){
                  message.success(response.data.msg);
                }
                console.log(this.$route)
                var redirect = this.$route.query.redirect
                if(redirect){
                  console.log(redirect.lastIndexOf("/?redirect="))
                  console.log(redirect.substring(redirect.lastIndexOf("/?redirect="),redirect.length))
                  redirect = redirect.substring(redirect.lastIndexOf("/?redirect="),redirect.length)
                  if(redirect.indexOf("/?redirect=") >= 0){
                    redirect = redirect.replaceAll("/?redirect=")
                  }
                  this.$router.push(redirect)
                }else{
                  this.$router.push({ name: "Dashboard" });
                }

                
              } else {
                message.error(response.data.msg);
                // this.getCaptcha();
              }
            });
          })
          .catch();
    },
    // check login statuc
    checkLogin() {
      // const api = new apiLogin();
      // api.get().then((response, any) => {
      //   if (1 == parseInt(response.data.code)) {
      //     this.$store.commit("setName", response.data.data.name);
      //     this.$store.commit("setUsername", response.data.data.username);
      //     this.$store.commit("setPortrait", response.data.data.portrait);
      //     this.$store.commit("setLastIp", response.data.data.last_ip);
      //     this.$store.commit("setLastTime", response.data.data.last_time);
      //     this.$router.push({ name: "Dashboard" });
      //   } else {
      //     this.getCaptcha();
      //   }
      // });
    },
  },
  mounted() {
    this.checkLogin();
    this.language = this.$store.getters.getLanguage;
  },
}

</script>


<style lang="less">
@import url("~@/assets/less/login.less");
</style>