<template>
  <Row id="RemainingVacation">
    <Col :span="24" class="title">
      <TypographyTitle>{{
        $t("nav.name.RemainingVacationManage")
      }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
      <Row :gutter="[16, 32]">
        <Col :span="24">
          <Form :model="searchForm">
            <Row :gutter="30">
              <Col :xs="24" :sm="4">
                <FormItem :label="$t('attendance.name.search')" name="search">
                  <Input :placeholder="$t('attendance.name.search')" v-model:value="searchForm.search"></Input>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="4">
                <FormItem :label="$t('remainingVacation.name.lessThan')" name="search">
                  <InputNumber  v-model:value="searchForm.lessThan"></InputNumber>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="4">
                <FormItem :label="$t('remainingVacation.name.moreThan')" name="search">
                  <InputNumber  v-model:value="searchForm.moreThan"></InputNumber>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="6">
                <FormItem :label="$t('remainingVacation.name.year')">
                  <DatePicker
                    mode="year"
                    :open="yearShowOne"
                    @openChange="openChangeOne"
                    @panelChange="panelChangeOne"
                    format="YYYY"
                    :placeholder="$t('report.name.month_placeholder')"
                    v-model:value="searchForm.year"
                    :popupStyle="{ zIndex: 9999999950 }"
                  ></DatePicker>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="6">
                <FormItem
                  :label="$t('attendance.name.department')"
                  name="department"
                >
                  <!--关联表的下拉选择-->
                  <Select v-model:value="searchForm.idDep">
                    <SelectOption :value="null">{{
                      $t("attendance.name.select_all")
                    }}</SelectOption>
                    <SelectOption
                      :key="sk"
                      v-for="(item, sk) in selectList['departmentList']"
                      :value="item['idDep']"
                      >{{ item["name"] }}</SelectOption
                    >
                  </Select>
                </FormItem>
              </Col>
              <Col :xs="24" :sm="8" class="text-right">
                <Button type="primary" @click="search_submit">{{
                  $t("public.name.search")
                }}</Button
                >&nbsp;
                <Button @click="search_reset">{{
                  $t("public.name.reset")
                }}</Button>&nbsp;
                <Button type="primary" @click="exportXml">{{$t('public.name.export')}}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col :span="24">
          <Table
            :columns="columns"
            :dataSource="list"
            :scroll="{ x: true }"
            rowKey="idRemVac"
            :loading="loading"
            :pagination="tablePagination"
            @change="chang_page"
          >
            <template #actionTitle>
              <span>
                {{ $t("public.table.action") }}
                <!-- <Button v-if="checkPermission('RemainingVacationManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button> -->
              </span>
            </template>
            <template #action="{ text }">
              <div>
                <!-- <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('RemainingVacationManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('RemainingVacationManage.Delete')" type="primary" danger size="small" @click="del(text.idRemVac)">{{$t("public.name.del")}}</Button>
                </div> -->
                <Dropdown :trigger="['click']">
                  <template #overlay>
                    <Menu>
                      <MenuItem @click="detail(text)">{{
                        $t("public.name.detail")
                      }}</MenuItem>
                      <MenuItem
                        v-if="checkPermission('RemainingVacationManage.Edit')"
                        @click="edit(text)"
                        >{{ $t("public.name.edit") }}</MenuItem
                      >
                    </Menu>
                  </template>
                  <Button size="small">
                    {{ $t("public.name.more") }}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </template>
          </Table>
        </Col>
      </Row>
    </Col>
    <RemainingVacationDetail
      ref="refRemainingVacationDetail"
    ></RemainingVacationDetail>
    <RemainingVacationEdit
      ref="refRemainingVacationEdit"
      @updateList="get_list"
    ></RemainingVacationEdit>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import {
  Row,
  Col,
  Table,
  Typography,
  Tag,
  Button,
  Dropdown,
  Menu,
  Modal,
  Form,
  Input,
  Select,
  message,
  MonthPicker,
  DatePicker,
  InputNumber,
} from "ant-design-vue";
import {
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { RemainingVacationList } from "@/api/RemainingVacationList";
import { RemainingVacationDelete } from "@/api/RemainingVacationDelete";
import { DepartmentListAll } from "@/api/DepartmentListAll";
import { RemainingVacationExport } from "@/api/RemainingVacationExport";
import RemainingVacationDetail from "@/components/Home/RemainingVacationDetail";
import RemainingVacationEdit from "@/components/Home/RemainingVacationEdit";
import moment from "moment";

export default {
  name: "RemainingVacationManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    RemainingVacationDetail,
    RemainingVacationEdit,
    MonthPicker,
    DatePicker,
      InputNumber,
  },
  data() {
    return {
      yearShowOne: false,
      searchForm: {
        search: "",
        year: moment({}),
        idDep: null,
        lessThan: null,
        moreThan: null,
        remainingAnnualLeaveDaysSort: null,
        remainingMaternityLeaveDaysSort: null,
        remainingPaternityLeaveDaysSort: null,
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
        {
          title: this.$t("remainingVacation.table.id_rem_vac"),
          fixed: "left",
          dataIndex: "idRemVac",
          key: "idRemVac",
        },
        {
          title: this.$t("remainingVacation.table.id_emp"),
          dataIndex: "employeeNickName",
          key: "employeeNickName",
        },
        {
          title: this.$t("remainingVacation.table.annual_leave_days"),
          dataIndex: "annualLeaveDays",
          key: "annualLeaveDays",
          customRender: ({text,record}) =>{
              // var days =  Math.floor(text/480);
              // var hours =  Math.floor(text%480/60);
              // var minutes =  text%60;
              // var result = '';
              // if(days !== 0){
              //   result = days + '天';
              // }
              // if(hours !== 0){
              //   result = result + hours + "小時"
              // }
              // if(minutes !== 0){
              //   result = result + minutes + "分鐘"
              // }
              // return result;
              return text + '天'
            }
        },
        // {
        //   title: this.$t("remainingVacation.table.maternity_leave_days"),
        //   dataIndex: "maternityLeaveDays",
        //   key: "maternityLeaveDays",
        // },
        // {
        //   title: this.$t("remainingVacation.table.paternity_leave_days"),
        //   dataIndex: "paternityLeaveDays",
        //   key: "paternityLeaveDays",
        // },
        {
          title: this.$t("remainingVacation.table.remaining_annual_leave_days"),
          dataIndex: "remainingAnnualLeaveDays",
          sorter: true,
          key: "remainingAnnualLeaveDays", customRender: ({text,record}) =>{
              // var days =  Math.floor(text/480);
              // var hours =  Math.floor(text%480/60);
              // var minutes =  text%60;
              // var result = '';
              // if(days !== 0){
              //   result = days + '天';
              // }
              // if(hours !== 0){
              //   result = result + hours + "小時"
              // }
              // if(minutes !== 0){
              //   result = result + minutes + "分鐘"
              // }
              // return result;
              return text + '天'
            }
        },
        {
          title: this.$t(
            "remainingVacation.table.remaining_maternity_leave_days"
          ),
          dataIndex: "remainingMaternityLeaveDays",
          sorter: true,
          key: "remainingMaternityLeaveDays", customRender: ({text,record}) =>{
              // var days =  Math.floor(text/480);
              // var hours =  Math.floor(text%480/60);
              // var minutes =  text%60;
              // var result = '';
              // if(days !== 0){
              //   result = days + '天';
              // }
              // if(hours !== 0){
              //   result = result + hours + "小時"
              // }
              // if(minutes !== 0){
              //   result = result + minutes + "分鐘"
              // }
              // return result;
              return text + '天'
            }
        },
        {
          title: this.$t(
            "remainingVacation.table.remaining_paternity_leave_days"
          ),
          dataIndex: "remainingPaternityLeaveDays",
          sorter: true,
          key: "remainingPaternityLeaveDays", customRender: ({text,record}) =>{
              // var days =  Math.floor(text/480);
              // var hours =  Math.floor(text%480/60);
              // var minutes =  text%60;
              // var result = '';
              // if(days !== 0){
              //   result = days + '天';
              // }
              // if(hours !== 0){
              //   result = result + hours + "小時"
              // }
              // if(minutes !== 0){
              //   result = result + minutes + "分鐘"
              // }
              // return result;
              return text + '天'
            }
        },
        { title: this.$t("remainingVacation.table.remaining_sick_leave_days"), dataIndex: "remainingSickLeaveDays",sorter: true, key: "remainingSickLeaveDays", customRender: ({text,record}) =>{
              // var days =  Math.floor(text/480);
              // var hours =  Math.floor(text%480/60);
              // var minutes =  text%60;
              // var result = '';
              // if(days !== 0){
              //   result = days + '天';
              // }
              // if(hours !== 0){
              //   result = result + hours + "小時"
              // }
              // if(minutes !== 0){
              //   result = result + minutes + "分鐘"
              // }
              // return result;
              return text + '天'
            }
        },
        {
          title: this.$t("remainingVacation.table.year"),
          dataIndex: "year",
          key: "year",
        },
        {
          title: this.$t("remainingVacation.table.remark"),
          dataIndex: "remark",
          key: "remark",
        },
        {
          key: "action",
          fixed: "right",
          slots: { title: "actionTitle", customRender: "action" },
        },
      ],
      selectList: {
        departmentList: [],
        employeeList: [],
      },
    };
  },
  methods: {
    chang_page(pagination,filters,sorter) {
      if(sorter){
        debugger
        this.searchForm.remainingAnnualLeaveDaysSort = null;
        this.searchForm.remainingMaternityLeaveDaysSort = null;
        this.searchForm.remainingPaternityLeaveDaysSort = null;
        this.searchForm.remainingSickLeaveDaysSort = null;
        if(sorter.columnKey === 'remainingAnnualLeaveDays'){
          this.searchForm.remainingAnnualLeaveDaysSort = (sorter.order == 'ascend'? 'asc' : 'desc');
        }
        if(sorter.columnKey === 'remainingMaternityLeaveDays'){
          this.searchForm.remainingMaternityLeaveDaysSort = (sorter.order == 'ascend'? 'asc' : 'desc');
        }
        if(sorter.columnKey === 'remainingPaternityLeaveDays'){
          this.searchForm.remainingPaternityLeaveDaysSort = (sorter.order == 'ascend'? 'asc' : 'desc');
        }
        if(sorter.columnKey === 'remainingSickLeaveDays'){
          this.searchForm.remainingSickLeaveDaysSort = (sorter.order == 'ascend'? 'asc' : 'desc');
        }
      }
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new RemainingVacationList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      };
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      if (this.searchForm.year) getData.year = this.searchForm.year.format("YYYY");
      if (this.searchForm.idDep) getData.idDep = this.searchForm.idDep
      if (this.searchForm.lessThan) getData.lessThan = this.searchForm.lessThan
      if (this.searchForm.moreThan) getData.moreThan = this.searchForm.moreThan
      if (this.searchForm.remainingAnnualLeaveDaysSort) getData.remainingAnnualLeaveDaysSort = this.searchForm.remainingAnnualLeaveDaysSort
      if (this.searchForm.remainingMaternityLeaveDaysSort) getData.remainingMaternityLeaveDaysSort = this.searchForm.remainingMaternityLeaveDaysSort
      if (this.searchForm.remainingPaternityLeaveDaysSort) getData.remainingPaternityLeaveDaysSort = this.searchForm.remainingPaternityLeaveDaysSort
      if (this.searchForm.remainingSickLeaveDaysSort) getData.remainingSickLeaveDaysSort = this.searchForm.remainingSickLeaveDaysSort
      api.get(getData).then((response) => {
        this.loading = false;
        if (0 === parseInt(response.data.code)) {
          const dataTmp = response.data.data;
          this.tablePagination.current = parseInt(dataTmp.current);
          this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
          this.tablePagination.total = parseInt(dataTmp.total);
          this.list = dataTmp.data;
          //如果當前處於不是第一頁，但是又沒有數據的情況，則把頁面設置為1，並重新請求
            if(this.list.length == 0 && this.tablePagination.current != 1){
              this.tablePagination.current = 1;
              this.get_list();
            }
        } else {
          this.list = [];
        }
      });
    },
    add() {
      this.$refs.refRemainingVacationEdit.addShow();
    },
    detail(obj) {
      this.$refs.refRemainingVacationDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refRemainingVacationEdit.editShow(obj);
    },
    del(idRemVac) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new RemainingVacationDelete();
          api.post({ idRemVac: idRemVac }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    exportXml() {
      const api = new RemainingVacationExport();
      const getData = {
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      if (this.searchForm.year) getData.year = this.searchForm.year.format("YYYY");
      if (this.searchForm.idDep) getData.idDep = this.searchForm.idDep
      if (this.searchForm.lessThan) getData.lessThan = this.searchForm.lessThan
      if (this.searchForm.moreThan) getData.moreThan = this.searchForm.moreThan
      if (this.searchForm.remainingAnnualLeaveDaysSort) getData.remainingAnnualLeaveDaysSort = this.searchForm.remainingAnnualLeaveDaysSort
      if (this.searchForm.remainingMaternityLeaveDaysSort) getData.remainingMaternityLeaveDaysSort = this.searchForm.remainingMaternityLeaveDaysSort
      if (this.searchForm.remainingPaternityLeaveDaysSort) getData.remainingPaternityLeaveDaysSort = this.searchForm.remainingPaternityLeaveDaysSort
      api
        .download(getData)
        .then((response) => {
          // console.log(decodeURI(res.headers['filename']));
          let blob = new Blob([response.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download = decodeURI(response.headers['filename']); // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        });
    },
    getDepartmentList() {
      const api = new DepartmentListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.departmentList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    search_submit() {
      this.get_list();
    },
    search_reset() {
      this.searchForm = {
        search: "",
        year: moment({}),
        idDep: null,
        lessThan: null,
        moreThan: null,
        remainingAnnualLeaveDaysSort: null,
        remainingMaternityLeaveDaysSort: null,
        remainingPaternityLeaveDaysSort: null,
      };
      this.get_list();
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.searchForm.year = value;
      this.yearShowOne = false;
    },
  },
  mounted() {
    this.get_list();
    this.getDepartmentList();
  },
};
</script>

<style lang="less">
@import url("~@/assets/less/home/remainingVacation.less");
</style>