<template>
  <Modal
    id="NewReportDetail"
    width="40%"
    :visible="visible"
    @cancel="visible = !visible"
    @ok="visible = !visible"
    :title="$t('report.name.detailTitle')"
    :zIndex="9999999900"
  >
    <PageHeader
      style="border-left: 5px solid rgb(24, 144, 255)"
      :title="data.employeeNameTw + '[' + data.idEmp + ']'"
      :sub-title="data.month"
    />
    <div
      style="
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #fafdfc;
        border-radius: 10px;
      "
    >
      <Row style="font-size: 14pt;margin-bottom:30px;">
        <Col span="12" style="text-align: left; ">
          {{$t('report.field.base_salary')}}
        </Col>
        <Col span="12" style="text-align: right;">
          ${{money(data.baseSalary)}}
        </Col>
      </Row>
      <Row style="font-size: 12pt;
            color: #7d8199;
            height: 30px;margin-bottom:30px;">
        <Col
          span="12"
          style="
            text-align: left;
          "
        >
          {{$t('report.field.house_subsidy')}}
        </Col>
        <Col
          span="12"
          style="
            text-align: right;
          "
        >
          ${{money(data.houseSubsidy)}}
        </Col>
        </Row>
        <Row style="font-size: 12pt;color: #7d8199;height: 30px;margin-bottom:30px;">
          <Col span="12" style="text-align: left;">
            {{$t('report.field.bonus')}}
          </Col>
          <Col span="12" style="text-align: right;">
            ${{money(data.bonus)}}
          </Col>
        </Row>
        <Row style="font-size: 12pt;color: #7d8199;height: 30px;margin-bottom:30px;">
          <Col span="12" style="text-align: left;">
            {{$t('report.field.allowance')}}
          </Col>
          <Col span="12" style="text-align: right;">
            ${{money(data.allowance)}}
          </Col>
        </Row>
        <Row style="font-size: 12pt;color: #7d8199;height: 30px;margin-bottom:30px;">
          <Col span="12" style="text-align: left;">
            {{$t('report.field.car_subsidy')}}
          </Col>
          <Col span="12" style="text-align: right;">
            ${{money(data.carSubsidy)}}
          </Col>
        </Row>
        <Row style="font-size: 12pt;color: #7d8199;height: 30px;margin-bottom:30px;">
          <Col span="12" style="text-align: left;">
            {{$t('report.field.commission')}}
          </Col>
          <Col span="12" style="text-align: right;">
            ${{money(data.commission)}}
          </Col>
        </Row>
        <Row style="font-size: 12pt;color: #7d8199;height: 30px;margin-bottom:30px;">
          <Col span="12" style="text-align: left;">
            {{$t('report.field.mpf')}}
          </Col>
          <Col span="12" style="text-align: right;">
            ${{money(data.mpf)}}
          </Col>
        </Row>
        <Row style="font-size: 12pt;color: #7d8199;height: 30px;margin-bottom:30px;">
          <Col span="12" style="text-align: left;">
            {{$t('report.field.other_deductions')}}
          </Col>
          <Col span="12" style="text-align: right;">
            ${{money(data.otherDeductions)}}
          </Col>
        </Row>
        <Row style="font-size: 12pt;color: #7d8199;height: 30px;margin-bottom:30px;">
          <Col span="12" style="text-align: left;">
            {{$t('report.field.other_subsidy')}}
          </Col>
          <Col span="12" style="text-align: right;">
            ${{money(data.otherSubsidy)}}
          </Col>
        </Row>
        <Row style="font-size: 12pt;color: #7d8199;height: 30px;margin-bottom:30px;">
          <Col span="12" style="text-align: left;">
            {{$t('report.field.remark')}}
          </Col>
          <Col span="12" style="text-align: right;">
            {{data.remark}}
          </Col>
        </Row>
        <Row style="font-size: 14pt;margin-bottom:30px;">
          <Col span="12" style="text-align: left; ">
            {{$t('report.field.total_salary')}}
          </Col>
          <Col span="12" style="text-align: right;">
            ${{money(data.totalSalary)}}
          </Col>
        </Row>
    </div>
    <div
      style="
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 20px;
        background-color: #FAFAFD;
        border-radius: 10px;
      "
    >
      <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="8" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.overtime_pay')}}
          </div>
          <div style="font-size:16pt">
            ${{money(data.overtimePay)}}
          </div>
        </Col>
      
        <Col span="8" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.overtime_times')}}
          </div>
          <div style="font-size:16pt">
            {{data.overtimeTimes}}
          </div>
        </Col>
        
        <Col span="8" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.overtime')}}
          </div>
          <div style="font-size:16pt">
            {{data.overtime}}
          </div>
        </Col>
      </Row>
    </div>
    

    <div
      style="
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 20px;
        background-color: #FAFAFD;
        border-radius: 10px;
      "
    >
    <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="12" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.attendance_days')}}
          </div>
          <div style="font-size:16pt">
            {{data.attendanceDays}}
          </div>
        </Col>
      
        <Col span="12" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.rest_days')}}
          </div>
          <div style="font-size:16pt">
            {{data.restDays}}
          </div>
        </Col>
        
      </Row>
      <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="12" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.early_leave_times')}}
          </div>
          <div style="font-size:16pt">
            {{data.earlyLeaveTimes}}
          </div>
        </Col>
      
        <Col span="12" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.early_leave_time')}}
          </div>
          <div style="font-size:16pt">
            {{data.earlyLeaveTime}}
          </div>
        </Col>
        
      </Row>
      <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="12" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.late_times')}}
          </div>
          <div style="font-size:16pt">
            {{data.lateTimes}}
          </div>
        </Col>
      
        <Col span="12" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.late_time')}}
          </div>
          <div style="font-size:16pt">
            {{data.lateTime}}
          </div>
        </Col>
        
      </Row>
      <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="12" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.serious_late_times')}}
          </div>
          <div style="font-size:16pt">
            {{data.seriousLateTimes}}
          </div>
        </Col>
      
        <Col span="12" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.serious_late_time')}}
          </div>
          <div style="font-size:16pt">
            {{data.seriousLateTime}}
          </div>
        </Col>
        
      </Row>
    </div>

    <div
      style="
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 20px;
        background-color: #FAFDFC;
        border-radius: 10px;
      "
    >
      <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="12" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.total_annual_leave')}}
          </div>
          <div style="font-size:16pt">
            {{data.totalAnnualLeave}}
          </div>
        </Col>
      
        <Col span="12" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.total_use_annual_leave')}}
          </div>
          <div style="font-size:16pt">
            {{data.totalUseAnnualLeave}}
          </div>
        </Col>
        
      </Row>
      <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="12" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.leave_time')}}
          </div>
          <div style="font-size:16pt">
            {{data.leaveTime}}
          </div>
        </Col>
      
        <Col span="12" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.total_use_annual_leave')}}
          </div>
          <div style="font-size:16pt">
            {{data.totalUseAnnualLeave}}
          </div>
        </Col>
        
      </Row>
    </div>

    <div
      style="
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px;
        padding-bottom: 20px;
        background-color: #FAFAFD;
        border-radius: 10px;
      "
    >
      <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="12" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.absence_days')}}
          </div>
          <div style="font-size:16pt">
            {{data.absenceDays}}
          </div>
        </Col>
      
        <Col span="12" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.absenteeism_times')}}
          </div>
          <div style="font-size:16pt">
            {{data.absenteeismTimes}}
          </div>
        </Col>
        
      </Row>
      <Row style="font-size: 14pt;margin-bottom:10px;">
        <Col span="12" style="text-align: center;border-right:1px solid rgb(217, 218, 224)">
          <div style="color:#7D8199">
            {{$t('report.field.no_check_in_times')}}
          </div>
          <div style="font-size:16pt">
            {{data.noCheckInTimes}}
          </div>
        </Col>
      
        <Col span="12" style="text-align: center;">
          <div style="color:#7D8199">
            {{$t('report.field.no_check_out_times')}}
          </div>
          <div style="font-size:16pt">
            {{data.noCheckOutTimes}}
          </div>
        </Col>
        
      </Row>
      
    </div>
    
    <!-- <Descriptions >
        <DescriptionsItem :label="$t('report.field.id_rep')">{{data.idRep}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.id_emp')">{{data.idEmp}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.month')">{{data.month}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.base_salary')">{{data.baseSalary}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.allowance')">{{data.allowance}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.car_subsidy')">{{data.carSubsidy}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.house_subsidy')">{{data.houseSubsidy}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.other_subsidy')">{{data.otherSubsidy}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.commission')">{{data.commission}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.overtime_times')">{{data.overtimeTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.overtime_pay')">{{data.overtimePay}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.overtime')">{{data.overtime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.bonus')">{{data.bonus}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.compensatory_leave_time')">{{data.compensatoryLeaveTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.total_use_annual_leave')">{{data.totalUseAnnualLeave}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.total_annual_leave')">{{data.totalAnnualLeave}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.use_annual_leave')">{{data.useAnnualLeave}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.remark')">{{data.remark}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.leave_time')">{{data.leaveTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.absence_days')">{{data.absenceDays}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.create_time')">{{data.createTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.mpf')">{{data.mpf}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.other_deductions')">{{data.otherDeductions}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.total_salary')">{{data.totalSalary}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.attendance_days')">{{data.attendanceDays}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.rest_days')">{{data.restDays}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.work_hours')">{{data.workHours}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.late_time')">{{data.lateTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.late_times')">{{data.lateTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.serious_late_times')">{{data.seriousLateTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.serious_late_time')">{{data.seriousLateTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.absenteeism_times')">{{data.absenteeismTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.early_leave_time')">{{data.earlyLeaveTime}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.early_leave_times')">{{data.earlyLeaveTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.no_check_in_times')">{{data.noCheckInTimes}}</DescriptionsItem>
        <DescriptionsItem :label="$t('report.field.no_check_out_times')">{{data.noCheckOutTimes}}</DescriptionsItem>
      <DescriptionsItem :label="$t('report.field.status')">{{selectList.statusList.find(item=>item.value===data.status).label}}</DescriptionsItem>
      <DescriptionsItem :label="$t('report.field.need_attendance_days')">{{data.needAttendanceDays}}</DescriptionsItem>
  </Descriptions> -->
  </Modal>
</template>

<script>
import {
  Modal,
  Descriptions,
  DescriptionsItem,
  PageHeader,
  Row,
  Col,
  Divider,
} from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default {
  name: "ReportDetail",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem,
    PageHeader,
    Row,
    Col,
    Divider,
  },
  data() {
    return {
      selectList: {
        statusList: [
          { value: 0, label: "δ�l" },
          { value: 1, label: "�Ѱl" },
        ],
      },
      data: {},
      visible: false,
    };
  },
  computed: {},
  methods: {
    async detailShow(obj) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
};
</script>

<style lang="less">
</style>