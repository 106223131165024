<template>
  <Row id="Report">
    <Col :span="24" class="title">
    <TypographyTitle>{{ $t('nav.name.ReportManage') }}</TypographyTitle>
    </Col>
    <Col :span="24" class="content">
    <Row :gutter="[16, 32]">
      <Col :span="24">
      <Form :model="searchForm">
        <Row :gutter="30">
          <Col :xs="24" :sm="4">
          <FormItem :label="$t('report.name.month')">
            <MonthPicker valueFormat="YYYY-MM" :placeholder="$t('report.name.month_placeholder')"
              v-model:value="searchForm.month" :popupStyle="{ zIndex: 9999999950 }"></MonthPicker>
          </FormItem>
          </Col>
          <Col :xs="24" :sm="8">
          <FormItem :label="$t('report.name.search')" name="search">
            <Input :placeholder="$t('report.name.search')" v-model:value="searchForm.search"></Input>
          </FormItem>
          </Col>
          <Col :xs="24" :sm="8">
          <FormItem :label="$t('attendance.name.department')" name="department">
            <!--关联表的下拉选择-->
            <Select v-model:value="searchForm.idDep">
              <SelectOption :value="null">{{ $t('attendance.name.select_all') }}</SelectOption>
              <SelectOption :key="sk" v-for="(item, sk) in selectList['departmentList']" :value="item['idDep']">{{
                  item['name']
              }}</SelectOption>
            </Select>
          </FormItem>
          </Col>
          <Col :xs="24" :sm="8">
          <FormItem :label="$t('employee.name.status')">
            <Select v-model:value="searchForm.empStatus" :placeholder="$t('public.placeholder.please_select')">
              <SelectOption :value="null">{{ $t('employee.name.status_all') }}</SelectOption>
              <SelectOption :value="0">{{ $t('employee.name.status_be_on_the_job') }}</SelectOption>
              <SelectOption :value="1">{{ $t('employee.name.status_quit') }}</SelectOption>
            </Select>
          </FormItem>
          </Col>
          <Col :xs="24" :sm="12">
          <Button type="primary" @click="search_submit">{{ $t('public.name.search') }}</Button>&nbsp;
          <Button @click="search_reset">{{ $t('public.name.reset') }}</Button>&nbsp;
          <Button v-if="checkPermission('ReportManage.GeneralSalary')" type="primary" @click="generalSalary">{{
              $t('report.name.generalSalary')
          }}</Button>&nbsp;
          <Button v-if="checkPermission('ReportManage.BatchDelete')" type="primary" danger @click="batchDelete">{{
              $t('report.name.batchDelete')
          }}</Button>&nbsp;
          <Button v-if="checkPermission('ReportManage.BatchConfirm')" type="primary" danger @click="batchConfirm">{{
              $t('report.name.batchConfirm')
          }}</Button>&nbsp;
          <Button type="primary" @click="exportXml">{{ $t('public.name.export') }}</Button>&nbsp;
          <Button type="primary" @click="down_payroll_report">{{ $t('report.name.down_payroll_report') }}</Button>&nbsp;
          <Button type="primary" @click="down_yearly_payroll_report">{{ $t('report.name.down_yearly_payroll_report')
          }}</Button>
          </Col>
        </Row>
      </Form>
      </Col>
      <Col :span="24">
      <Table :columns="columns" :dataSource="list" :scroll="{ x: true }" rowKey="idRep" :loading="loading"
        :pagination="tablePagination" @change="chang_page">
        <template #actionTitle>
          <span>
            {{ $t("public.table.action") }}
            <!-- <Button v-if="checkPermission('ReportManage.Add')" type="primary" size="small" shape="circle" @click="add()">
                  <PlusOutlined />
                </Button> -->
          </span>
        </template>
        <template #action="{ text, record }">
          <div>
            <!-- <div class="pc-display">
                  <Button type="default" size="small" @click="detail(text)">{{$t("public.name.detail")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('ReportManage.Edit')" type="primary" size="small" @click="edit(text)">{{$t("public.name.edit")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('ReportManage.Delete')" type="primary" danger size="small" @click="del(text.idRep)">{{$t("public.name.del")}}</Button>
                  <span>&nbsp;</span>
                  <Button v-if="checkPermission('ReportManage.Confirm')" type="primary" size="small" @click="confirm(text.idRep)">{{$t("public.name.confirm")}}</Button>
                </div> -->
            <Dropdown :trigger="['click']">
              <template #overlay>
                <Menu>
                  <MenuItem @click="detail(text)">{{ $t("public.name.detail") }}</MenuItem>
                  <MenuItem v-if="checkPermission('ReportManage.Edit')" @click="edit(text)">{{ $t('public.name.edit') }}
                  </MenuItem>
                  <MenuItem v-if="checkPermission('ReportManage.Delete') && record.status === 0" danger
                    @click="del(text.idRep)">{{ $t('public.name.del') }}</MenuItem>
                  <MenuItem v-if="checkPermission('ReportManage.Confirm') && record.status === 0" danger
                    @click="confirm(text.idRep)">{{ $t('public.name.confirm') }}</MenuItem>
                  <MenuItem v-if="checkPermission('ReportManage.DownSlip')" danger @click="downSlip(text.idRep)">
                  {{ $t('report.name.downSlip') }}</MenuItem>
                  <MenuItem v-if="checkPermission('ReportManage.DownSlip')" danger @click="sendSlip(text.idRep)">
                  {{ $t('report.name.sendSlip') }}</MenuItem>
                </Menu>
              </template>
              <Button size="small">
                {{ $t("public.name.more") }}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </template>
        <template #footer>{{ $t("report.name.totalSalary") }}:${{ money(totalSalary.totalSalary) }} &nbsp;&nbsp;&nbsp;
          {{ $t("report.name.totalMpf") }}:${{ money(totalSalary.totalMpf) }}&nbsp;&nbsp;&nbsp;
          {{ $t("report.name.totalRealSalary") }}:${{ money(totalSalary.totalRealSalary) }}
        </template>
      </Table>
      </Col>
    </Row>
    </Col>
    <!-- <ReportDetail ref="refReportDetail"></ReportDetail> -->
    <NewReportDetail ref="refNewReportDetail"></NewReportDetail>
    <ReportEdit ref="refReportEdit" @updateList="get_list"></ReportEdit>
    <GeneralSalary ref="refGeneralSalary" @updateList="get_list"></GeneralSalary>
    <ReportConfirm ref="refReportConfirm" @updateList="get_list"></ReportConfirm>
    <ReportBatchConfirm ref="refReportBatchConfirm" @updateList="get_list"></ReportBatchConfirm>
    <DownYearlyPayrollReport ref="refDownYearlyPayrollReport" @updateList="get_list"></DownYearlyPayrollReport>
  </Row>
</template>

<script>
import { createVNode } from "vue";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Form, Input, Select, message, MonthPicker } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { ReportList } from "@/api/ReportList";
import { ReportDelete } from "@/api/ReportDelete"
import { ReportBatchDelete } from "@/api/ReportBatchDelete"
import { ReportGetTotalSalary } from "@/api/ReportGetTotalSalary"
import { DepartmentListAll } from "@/api/DepartmentListAll";
import { ReportDownPaymentSlip } from "@/api/ReportDownPaymentSlip";
import { ReportSendPaymentSlip } from "@/api/ReportSendPaymentSlip";
import { ReportExport } from "@/api/ReportExport";
import { ReportDownPayrollReport } from "@/api/ReportDownPayrollReport";
import ReportDetail from "@/components/Home/ReportDetail";
import NewReportDetail from "@/components/Home/NewReportDetail";
import ReportEdit from "@/components/Home/ReportEdit";
import GeneralSalary from "@/components/Home/GeneralSalary";
import ReportConfirm from "@/components/Home/ReportConfirm";
import ReportBatchConfirm from "@/components/Home/ReportBatchConfirm";
import DownYearlyPayrollReport from "@/components/Home/DownYearlyPayrollReport";
import moment from "moment";

export default {
  name: "ReportManage",
  components: {
    Row,
    Col,
    Table,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Input,
    Select,
    SelectOption: Select.Option,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    ReportDetail,
    ReportEdit,
    GeneralSalary,
    MonthPicker,
    NewReportDetail,
    ReportConfirm,
    ReportBatchConfirm,
    DownYearlyPayrollReport,
  },
  data() {
    return {
      searchForm: {
        month: moment(new Date()).subtract(1, 'months').format('YYYY-MM'),
        idDep: null,
        search: "",
        empStatus: null,
      },
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      totalSalary: {
        totalSalary: 0,
        totalMpf: 0,
        totalRealSalary: 0,
      },
      loading: false,
      list: [],
      columns: [
        { title: this.$t("report.table.id_rep"), width: 80, fixed: "left", dataIndex: "idRep", key: "idRep" },
        { title: this.$t("report.table.id_emp"), width: 100, fixed: "left", dataIndex: "employeeNickName", key: "employeeNickName" },
        { title: this.$t("report.table.month"), width: 100, fixed: "left", dataIndex: "month", key: "month" },
        { title: this.$t("report.table.pay_date"), width: 110, fixed: "left", dataIndex: "payDate", key: "payDate" },
        { title: this.$t("report.table.base_salary"), width: 100, dataIndex: "baseSalary", key: "baseSalary", customRender: ({text}) =>{
            return '$'+this.money(text)
          } 
        },

        // { title: this.$t("report.table.car_subsidy"), width: 100, dataIndex: "carSubsidy", key: "carSubsidy", customRender: ({text}) =>{
        //     return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        //   } 
        // },
        // { title: this.$t("report.table.house_subsidy"), width: 100, dataIndex: "houseSubsidy", key: "houseSubsidy", customRender: ({text}) =>{
        //     return '$'+text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        //   } 
        // },
        { title: this.$t("report.table.other_subsidy"), width: 100, dataIndex: "otherSubsidy", key: "otherSubsidy", customRender: ({text}) =>{
            return '$'+this.money(text)
          } 
        },

        // { title: this.$t("report.table.overtime_times"), width: 150, dataIndex: "overtimeTimes", key: "overtimeTimes", customRender: ({text,record}) =>{
        //     return text + "次"
        //   }
        // },
        {
          title: this.$t("report.table.overtime_pay"), width: 100, dataIndex: "overtimePay", key: "overtimePay", customRender: ({ text }) => {
            return '$' + this.money(text)
          }
        },
        { title: this.$t("report.table.commission"), width: 100, dataIndex: "commission", key: "commission", customRender: ({text}) =>{
            return '$'+this.money(text)
          } 
        },
        // { title: this.$t("report.table.overtime"), width: 100, dataIndex: "overtime", key: "overtime" },
        {
          title: this.$t("report.table.bonus"), width: 100, dataIndex: "bonus", key: "bonus", customRender: ({ text }) => {
            return '$' + this.money(text)
          }
        },
        { title: this.$t("report.table.allowance"), width: 100, dataIndex: "allowance", key: "allowance", customRender: ({text}) =>{
            return '$'+this.money(text)
          } 
        },
        // { title: this.$t("report.table.compensatory_leave_time"), width: 100, dataIndex: "compensatoryLeaveTime", key: "compensatoryLeaveTime", customRender: ({text,record}) =>{
        //     return text + "分鐘"
        //   }
        // },
        // { title: this.$t("report.table.total_use_annual_leave"), width: 100, dataIndex: "totalUseAnnualLeave", key: "totalUseAnnualLeave", customRender: ({text,record}) =>{
        //     return text + "天"
        //   }
        // },
        // { title: this.$t("report.table.total_annual_leave"), width: 100, dataIndex: "totalAnnualLeave", key: "totalAnnualLeave", customRender: ({text,record}) =>{
        //     return text + "天"
        //   }
        // },
        // { title: this.$t("report.table.use_annual_leave"), width: 100, dataIndex: "useAnnualLeave", key: "useAnnualLeave", customRender: ({text,record}) =>{
        //     return text + "天"
        //   }
        // },
        // { title: this.$t("report.table.remark"), width: 100, dataIndex: "remark", key: "remark" },
        // { title: this.$t("report.table.leave_time"), width: 100, dataIndex: "leaveTime", key: "leaveTime" },
        // { title: this.$t("report.table.absence_days"), width: 100, dataIndex: "absenceDays", key: "absenceDays" },
        // { title: this.$t("report.table.create_time"), width: 100, dataIndex: "createTime", key: "createTime" },
        {
          title: this.$t("report.table.mpf"), width: 120, dataIndex: "mpf", key: "mpf", customRender: ({ text }) => {
            return '$' + this.money(text)
          }
        },
        {
          title: this.$t("report.table.employer_mpf"), width: 120, dataIndex: "employerMpf", key: "employerMpf", customRender: ({ text }) => {
            return '$' + this.money(text)
          }
        },
        {
          title: this.$t("report.table.other_deductions"), width: 100, dataIndex: "otherDeductions", key: "otherDeductions", customRender: ({ text }) => {
            return '$' + this.money(text)
          }
        },
        {
          title: this.$t("report.table.total_salary"), width: 100, dataIndex: "totalSalary", key: "totalSalary", customRender: ({ text }) => {
            return '$' + this.money(text)
          }
        },
        // { title: this.$t("report.table.attendance_days"), width: 100, dataIndex: "attendanceDays", key: "attendanceDays" },
        // { title: this.$t("report.table.rest_days"), width: 100, dataIndex: "restDays", key: "restDays" },
        // { title: this.$t("report.table.work_hours"), width: 100, dataIndex: "workHours", key: "workHours", customRender: ({text}) =>{
        //     return text + "小時"
        //   }
        // },
        // { title: this.$t("report.table.late_time"), width: 100, dataIndex: "lateTime", key: "lateTime" },
        // { title: this.$t("report.table.late_times"), width: 100, dataIndex: "lateTimes", key: "lateTimes" },
        // { title: this.$t("report.table.serious_late_times"), width: 100, dataIndex: "seriousLateTimes", key: "seriousLateTimes" },
        // { title: this.$t("report.table.serious_late_time"), width: 100, dataIndex: "seriousLateTime", key: "seriousLateTime" },
        // { title: this.$t("report.table.absenteeism_times"), width: 100, dataIndex: "absenteeismTimes", key: "absenteeismTimes" },
        // { title: this.$t("report.table.early_leave_time"), width: 100, dataIndex: "earlyLeaveTime", key: "earlyLeaveTime" },
        // { title: this.$t("report.table.early_leave_times"), width: 100, dataIndex: "earlyLeaveTimes", key: "earlyLeaveTimes" },
        // { title: this.$t("report.table.no_check_in_times"), width: 100, dataIndex: "noCheckInTimes", key: "noCheckInTimes" },
        // { title: this.$t("report.table.no_check_out_times"), width: 100, dataIndex: "noCheckOutTimes", key: "noCheckOutTimes" },
        {
          title: this.$t("report.table.status"), dataIndex: "status", key: "status", customRender: ({ text }) => {
            return this.selectList.statusList.find(item => item.value === text).label
          }
        },
        { key: "action", width: 100, fixed: "right", slots: { title: "actionTitle", customRender: "action" } },
      ],
      selectList: {
        employeeList: [],
        statusList: [{ value: 0, label: '未發' }, { value: 1, label: '已發' }],
        departmentList: [],
      }
    };
  },
  methods: {
    chang_page(pagination) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new ReportList();
      const getData = {
        page: this.tablePagination.current,
        limit: this.tablePagination.pageSize,
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      if (this.searchForm.month != 0) getData.month = this.searchForm.month
      if (this.searchForm.idDep != 0) getData.idDep = this.searchForm.idDep
      if (this.searchForm.empStatus != "") getData.empStatus = this.searchForm.empStatus
      api
        .get(getData)
        .then((response) => {
          this.loading = false;
          if (0 === parseInt(response.data.code)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.current);
            this.tablePagination.pageSize = parseInt(dataTmp.pageSize);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.list = dataTmp.data;
            //如果當前處於不是第一頁，但是又沒有數據的情況，則把頁面設置為1，並重新請求
            if(this.list.length == 0 && this.tablePagination.current != 1){
              this.tablePagination.current = 1;
              this.get_list();
            }
            this.getTotalSalary();
          } else {
            this.list = [];
          }
        });
    },
    generalSalary() {
      this.$refs.refGeneralSalary.addShow();
    },
    add() {
      this.$refs.refReportEdit.addShow();
    },
    detail(obj) {
      this.$refs.refNewReportDetail.detailShow(obj);
    },
    edit(obj) {
      this.$refs.refReportEdit.editShow(obj);
    },
    down_payroll_report() {
      if (!this.searchForm.month)
        return;
      const api = new ReportDownPayrollReport();
      api.download({
        month: this.searchForm.month
      }).then((response) => {
        if (!response) {
          return
        }
        if(response.data.type == 'application/json'){
          message.error(this.$t("public.name.no_data"))
          return
        }
        let url = window.URL.createObjectURL(new Blob([response.data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', 'payrollReport.pdf')
        document.body.appendChild(link)
        link.click()
      });
    },
    down_yearly_payroll_report() {
      this.$refs.refDownYearlyPayrollReport.show();
    },
    del(idRep) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new ReportDelete();
          api.post({ idRep: idRep }).then((response) => {
            if (0 === parseInt(response.data.code)) {
              this.get_list();
              message.success(response.data.msg);
            } else {
              message.error(response.data.msg);
            }
          });
        },
      });
    },
    exportXml() {
      const api = new ReportExport();
      const getData = {
      }
      if (this.searchForm.search != "") getData.search = this.searchForm.search
      if (this.searchForm.month != 0) getData.month = this.searchForm.month
      if (this.searchForm.idDep != 0) getData.idDep = this.searchForm.idDep
      if (this.searchForm.empStatus != "") getData.empStatus = this.searchForm.empStatus
      api
        .download(getData)
        .then((response) => {
          // console.log(decodeURI(res.headers['filename']));
          let blob = new Blob([response.data], { type: "application/xlsx" });
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download = decodeURI(response.headers['filename']); // 重命名文件
          link.click();
          URL.revokeObjectURL(url);
        });
    },
    downSlip(idRep) {
      const api = new ReportDownPaymentSlip();
      api.download({
        idRep: idRep
      }).then((response) => {
        this.download(response)
      });
    },
    sendSlip(idRep) {
      const api = new ReportSendPaymentSlip();
      api.get({
        idRep: idRep
      }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          this.get_list();
          message.success(response.data.msg);
        } else {
          message.error(response.data.msg);
        }
      });
    },
    download(res) {
      if (!res) {
        return
      }
      let url = window.URL.createObjectURL(new Blob([res.data]))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', 'slip.pdf')
      document.body.appendChild(link)
      link.click()
    },
    batchDelete() {
      Modal.confirm({
        title: this.$t("report.name.are_you_sure_batch_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          if (this.searchForm.month && this.searchForm.month !== '') {
            const api = new ReportBatchDelete();
            api.post({ month: this.searchForm.month }).then((response) => {
              if (0 === parseInt(response.data.code)) {
                this.get_list();
                message.success(response.data.msg);
              } else {
                message.error(response.data.msg);
              }
            });
          } else {
            message.error(this.$t("report.validate.required.month"));
          }

        },
      });
    },
    confirm(idRep) {
      // Modal.confirm({
      //   title: this.$t("report.name.are_you_sure_confirm") + "?",
      //   content: this.$t("public.name.action_cannot_resumed"),
      //   icon: createVNode(ExclamationCircleOutlined),
      //   okText: this.$t("public.name.yes"),
      //   okType: "danger",
      //   cancelText: this.$t("public.name.no"),
      //   onOk: () => {
      //       const api = new ReportConfirm();
      //       api.post({ idRep: idRep }).then((response) => {
      //         if (0 === parseInt(response.data.code)) {
      //           this.get_list();
      //           message.success(response.data.msg);
      //         } else {
      //           message.error(response.data.msg);
      //         }
      //       });
      //   },
      // });
      this.$refs.refReportConfirm.show(idRep);

    },
    batchConfirm() {
      // Modal.confirm({
      //   title: this.$t("report.name.are_you_sure_batch_confirm") + "?",
      //   content: this.$t("public.name.action_cannot_resumed"),
      //   icon: createVNode(ExclamationCircleOutlined),
      //   okText: this.$t("public.name.yes"),
      //   okType: "danger",
      //   cancelText: this.$t("public.name.no"),
      //   onOk: () => {
      //     if(this.searchForm.month && this.searchForm.month !== ''){
      //       const api = new ReportBatchConfirm();
      //       api.post({ month: this.searchForm.month }).then((response) => {
      //         if (0 === parseInt(response.data.code)) {
      //           this.get_list();
      //           message.success(response.data.msg);
      //         } else {
      //           message.error(response.data.msg);
      //         }
      //       });
      //     }else{
      //       message.error(this.$t("report.validate.required.month"));
      //     }

      //   },
      // });
      if (this.searchForm.month) {
        this.$refs.refReportBatchConfirm.show(this.searchForm.month);
      } else {
        message.error(this.$t("report.validate.required.month"));
      }

    },
    getTotalSalary() {
      const api = new ReportGetTotalSalary();
      api.get({
        month: this.searchForm.month,
        search: this.searchForm.search
      }).then((response) => {
        if (0 === parseInt(response.data.code)) {
          this.totalSalary = response.data.data
        } else {
          message.error(response.data.msg);
        }
      });
    },
    getDepartmentList() {
      const api = new DepartmentListAll();
      api.get().then((response) => {
        if (0 == parseInt(response.data.code)) {
          this.selectList.departmentList = response.data.data;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    search_submit() {
      this.get_list()
    },
    search_reset() {
      this.searchForm = {
        month: moment(new Date()).subtract(1, 'months').format('YYYY-MM'),
        idDep: null,
        search: "",
        empStatus: null,
      };
      this.get_list()
    },
  },
  mounted() {
    this.get_list();
    this.getDepartmentList();
  },

}
</script>

<style lang="less">
@import url("~@/assets/less/home/report.less");
</style>